<template>
  <ClFormRow
    :label="label"
    :toolTipText="tooltip"
    tooltipPosition="label"
    col-label="10"
    sm-label="10"
    md-content="12"
    xl-content="12"
  >
    <template #content>
      <div class="zq--award-scheduling">
        <template v-for="(field, index) in meta">
          <Modal
            :modalShow="isShowModal"
            :title="modalTitle"
            :subTitle="modalSubTitle"
            :isCloseButton="true"
            :successBtnLabel="'Save'"
            :classes="['hide-cross']"
            :closeOnBackdrop="false"
            @doFunction="saveField(field)"
            @toggle-modal="closeModal"
            @closeModal="closeModal"
          >
            <template #body>
              <div>
                <SwitchButton
                  :isChecked="isChecked"
                  :textLabelOn="'Duration'"
                  :textLabelOff="'Point in Time'"
                  name="isPointInTime"
                  @toggle="updatePointInTimeHandler"
                  :height="'40px'"
                  :width="'240px'"
                  :isDisabled="false"
                />

                <DatePicker
                  v-if="isPointInTime"
                  :dateProp="tempDate"
                  @input="setTempDate"
                  :placeholder="'Point in time'"
                  class="schedule-startDate"
                  :disabled="false"
                  :disabledDate="disabledStartDate"
                />

                <div v-if="!isPointInTime" class="duration-wrapp">
                  <div class="duration-item">
                    <span class="duration-label">Months:</span>
                    <input
                      v-model="tempDateMon"
                      class="duration-input"
                      :placeholder="'0'"
                      :name="'Months'"
                      type="number"
                      min="0"
                      max="60"
                    />
                  </div>

                  <div class="duration-item">
                    <span class="duration-label">Weeks:</span>
                    <input
                      v-model="tempDateW"
                      class="duration-input"
                      :placeholder="'0'"
                      :name="'Weeks'"
                      type="number"
                      min="0"
                      max="60"
                    />
                  </div>

                  <div class="duration-item">
                    <span class="duration-label">Days:</span>
                    <input
                      v-model="tempDateD"
                      class="duration-input"
                      :placeholder="'0'"
                      :name="'Days'"
                      type="number"
                      min="0"
                      max="31"
                    />
                  </div>

                  <div class="duration-item">
                    <span class="duration-label">Hours:</span>
                    <input
                      v-model="tempDateH"
                      class="duration-input"
                      :placeholder="'0'"
                      :name="'Hours'"
                      type="number"
                      min="0"
                      max="60"
                    />
                  </div>

                  <div class="duration-item">
                    <span class="duration-label">Minutes:</span>
                    <input
                      v-model="tempDateMin"
                      class="duration-input"
                      :placeholder="'0'"
                      :name="'Minutes'"
                      type="number"
                      min="0"
                      max="60"
                    />
                  </div>

                  <div class="duration-item">
                    <span class="duration-label">Seconds:</span>
                    <input
                        v-model="tempDateS"
                        class="duration-input"
                        :placeholder="'0'"
                        :name="'Minutes'"
                        type="number"
                        min="0"
                        max="60"
                    />
                  </div>
                </div>
              </div>
            </template>
          </Modal>

          <CRow form :key="index" class="mb-2">
            <CCol :sm="colSm" :md="colMd" :lg="colLg" :xl="colXl" :class="paddingRight">
              <div class="field-label">On Award Status Change To:</div>
              <ClSelect
                name="onStatusChangeTo"
                class="text-capitalize"
                :options="getOnStatusChangeToOptions()"
                :placeholder="placeholders.onStatusChangeTo"
                :valueProp="field.onStatusChangeTo"
                size="xxl"
                required
                :selectLabel="'onStatusChangeTo'"
                @checkValue="(data) => {field.onStatusChangeTo = data.value; isInit = false}"
              />
            </CCol>

            <CCol :sm="colSm" :md="colMd" :lg="colLg" :xl="colXl" :class="paddingRight">
              <div class="field-label">Active From:</div>
              <ClRowInput
                type="text"
                name="thenAwardActiveFrom"
                v-model="field.thenAwardActiveFrom"
                :placeholder="placeholders.thenAwardActiveFrom"
                :disabled="true"
                @click.native="showModal('thenAwardActiveFrom', field.thenAwardActiveFrom, index, field.onStatusChangeTo)"
              />
            </CCol>

            <CCol :sm="colSm" :md="colMd" :lg="colLg" :xl="colXl" :class="paddingRight">
              <div class="field-label">Active Until:</div>
              <ClRowInput
                type="text"
                name="thenAwardActiveUntil"
                v-model="field.thenAwardActiveUntil"
                :placeholder="placeholders.thenAwardActiveUntil"
                :disabled="true"
                @click.native="showModal('thenAwardActiveUntil', field.thenAwardActiveUntil, index, field.onStatusChangeTo)"
              />
            </CCol>

            <CCol
              sm="4"
              md="3"
              lg="2"
              class="zq--metadata--actions d-flex align-items-end"
              v-show="!disabled"
            >
              <CButton
                type="button"
                :color="setBtnColor('danger')"
                size="sm"
                class="zq--small-button"
                @click="deleteField(index)"
              >
                <CIcon name="cil-minus" />
              </CButton>
              <CButton
                type="button"
                :color="setBtnColor('success')"
                class="zq--small-button ml-2"
                size="sm"
                :disabled="!field.onStatusChangeTo"
                @click="addField(index)"
                v-if="meta.length - 1 === index"
              >
                <CIcon name="cil-plus" />
              </CButton>
            </CCol>
          </CRow>
        </template>
      </div>
    </template>
  </ClFormRow>
</template>

<script>
import ClRowInput from '@/components/forms/ClRowInput';
import {cloneDeep, isEqual} from 'lodash';
import ClSelect from "@/shared/components/formComponents/ClSelect.vue";
import Modal from "@/shared/UI/Modal.vue";
import DatePicker from '@/shared/components/DatePicker';
import SwitchRow from '@/shared/components/SwitchRow';
import SwitchButton from "@/shared/UI/SwitchButton.vue";
import CIInput from "@/shared/components/formComponents/CIInputCol.vue";
import { parse } from "iso8601-duration";

export default {
  name: 'ZqAwardScheduling',
  components: {
    CIInput,
    SwitchButton,
    Modal,
    ClSelect,
    ClRowInput,
    DatePicker,
    SwitchRow,
  },
  props: {
    value: [Object, Array],
    disabled: Boolean,
    headers: Boolean,
    isValid: Boolean,
    invalidMessage: String,
    label: String,
    tooltip: String,
    colSm: {
      type: String,
      default: '4'
    },
    colMd: {
      type: String,
      default: '4'
    },
    colLg: {
      type: String,
      default: '5'
    },
    colXl: {
      type: String,
      default: '3'
    },
    paddingRight: {
      type: String,
      default: 'pr-0'
    }
  },
  data() {
    return {
      meta: [],
      isShowModal: false,
      isChecked: false,
      isInit: false,
      modalTitle: '',
      modalSubTitle: '',
      tempIndex: 0,
      tempParam: null,
      tempDate: null,
      isPointInTime: false,
      tempDateMon: 0,
      tempDateW: 0,
      tempDateD: 0,
      tempDateH: 0,
      tempDateMin: 0,
      tempDateS: 0,
      onStatusChangeToOptions: ['Issued', 'Claimed', 'Completed', 'Held', 'Processing', 'Delivered', 'Expired', 'Cancelled', 'Declined'],
      placeholders: {
        onStatusChangeTo: 'On Status Change To',
        thenAwardActiveFrom: 'Active From [Optional]',
        thenAwardActiveUntil: 'Active Until [Optional]'
      },
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    meta: {
      deep: true,
      handler() {
        this.$emit('input', this.meta);
      },
    },
  },
  methods: {
    initialize() {
      if (this.value && this.value.length) {
        this.isInit = true;
        this.meta = cloneDeep(this.value);
      } else {
        this.blankMeta();
      }
    },
    blankMeta() {
      this.meta = [{ onStatusChangeTo: '', thenAwardActiveFrom: '', thenAwardActiveUntil: '' }];
    },
    addField: function (index) {
      this.meta.push({ onStatusChangeTo: '', thenAwardActiveFrom: '', thenAwardActiveUntil: '' });
    },
    deleteField: function (index) {
      if (this.meta.length === 1) {
        this.blankMeta();
      }
      if (this.meta.length > 1) {
        this.meta.splice(index, 1);
      }
    },
    setBtnColor(color) {
      switch (this.theme) {
        case 'main': {
          return '';
        }
        case 'default': {
          return color;
        }
        default: {
          return '';
        }
      }
    },
    getOnStatusChangeToOptions() {
      let options = cloneDeep(this.onStatusChangeToOptions);
      if (!this.isInit && this.meta && this.meta.length) {
        const statuses = this.meta.map(s => s.onStatusChangeTo);
        options = options.filter(o => !statuses.includes(o));
      }

      return options;
    },
    disabledStartDate(d) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return d < new Date(new Date().setHours(0, 0, 0, 0));
    },
    updatePointInTimeHandler() {
      this.isChecked = !this.isChecked;
      this.isPointInTime = this.isChecked;
    },
    setTempDate(date) {
      if (!date) return;
      this.tempDate = date;
      this.tempDateMon = 0;
      this.tempDateW = 0;
      this.tempDateD = 0;
      this.tempDateH = 0;
      this.tempDateMin = 0;
      this.tempDateS = 0;
    },
    showModal(param, value, index, onStatusChangeTo) {
      if (value) {
        if (value.length && value[0] === 'P') {
          const duration = parse(value);
          this.tempDateMon = duration.months;
          this.tempDateW = duration.weeks;
          this.tempDateD = duration.days;
          this.tempDateH = duration.hours;
          this.tempDateMin = duration.minutes;
          this.tempDateS = duration.seconds;
          this.isChecked = false;
          this.isPointInTime = this.isChecked;
        } else {
          this.tempDate = new Date(value);
          this.isChecked = true;
          this.isPointInTime = this.isChecked;
        }
      }

      if (param === 'thenAwardActiveFrom') {
        this.modalTitle = 'Set the active from duration';
        this.modalSubTitle = 'How long before the award becomes active after being set to ' + onStatusChangeTo.toLowerCase();
      } else {
        this.modalTitle = 'Set the active until duration';
        this.modalSubTitle = 'How long before the award is archived after being set to ' + onStatusChangeTo.toLowerCase();
      }

      this.tempIndex = index;
      this.tempParam = param;
      this.isShowModal = true;
    },
    closeModal() {
      this.tempDate = null;
      this.tempParam = null;

      this.isShowModal = false;
    },
    saveField(field) {
      if (field.hasOwnProperty(this.tempParam)) {
        if (this.tempDateMon || this.tempDateW || this.tempDateD || this.tempDateH || this.tempDateMin || this.tempDateS) {
          this.meta[this.tempIndex][this.tempParam] = 'P0Y' + this.tempDateMon + 'M' + this.tempDateW + 'W' + this.tempDateD + 'DT' + this.tempDateH + 'H' + this.tempDateMin + 'M' + this.tempDateS + 'S';
        } else {
          this.meta[this.tempIndex][this.tempParam] = this.tempDate instanceof Date ? new Date(this.tempDate.getTime() - this.tempDate.getTimezoneOffset() * 60000).toISOString() : this.tempDate;
        }
      }

      this.tempDate = null;
      this.tempParam = null;
      this.tempDateMon = 0;
      this.tempDateW = 0;
      this.tempDateD = 0;
      this.tempDateH = 0;
      this.tempDateMin = 0;
      this.tempDateS = 0;

      this.isShowModal = false;
    },
  },
};
</script>

<style lang="scss">
.zq--award-scheduling {
  .multiselect__placeholder {
    margin: 0;
    padding: 0;
  }
  .multiselect__single {
    padding: 0;
  }
  .zq-input-text--disabled > input {
    background-color: #ffffff !important;
    cursor: pointer;
  }
  .zq-input-text--disabled {
    border: none;
    cursor: pointer;
  }
  .field-label {
    opacity: 0.5;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .duration-input {
    border: none;
    height: 40px;
    width: 50px;
    padding-left: 8px !important;
    padding-right: 8px !important;
    margin-right: 16px;
  }
  .duration-input:focus {
    border: solid 1px #ebebeb;
    outline: none;
  }
  .duration-label {
    opacity: 0.5;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .duration-wrapp {
    display: flex;
  }
  .duration-item {
    display: flex;
    flex-direction: column;
  }
}
</style>
