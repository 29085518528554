<template>
  <div class="zq--checkbox--wrap">
    <label class="zq--checkbox mb-0">
      <input
          class="toggle__input"
          :id="checkboxId"
          type="checkbox"
          :name="value"
          checked
          :value="value"
          @change="handleInput(checked, $event)"
          @click="handleClick"
          v-model="checked"
      />
      <div class="toggle__label">
        <slot name="label"></slot>
        <i v-if="icon" :class="`fa ${icon} select-all-icon`" aria-hidden="true"></i>
        <span v-if="label" class="toggle__text">{{ label }}</span>
      </div>
      <IconWithTooltip v-if="toolTipText.length" :text="toolTipText"/>
    </label>
  </div>

</template>

<script>
import {mapGetters} from 'vuex';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';

export default {
  components: {
    IconWithTooltip,
  },
  props: {
    label: String,
    valueProp: [String, Boolean],
    checkedProp: Boolean,
    icon: {
      type: String,
      default: ''
    },
    toolTipText: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      value: '',
      checked: false,
      checkedNames: [],
      checkboxId: '',
    }
  },
  created() {
    this.checked = this.checkedProp;
    this.value = this.valueProp;
    this.checkboxId = this.id ? this.id : this.valueProp;
  },
  computed: {
    ...mapGetters('theme', ['theme']),
  },
  methods: {
    handleInput(val, event) {
      this.$emit('handler', {value: val, event});
    },
    handleClick() {
      this.$emit('click')
    }
  },
  watch: {
    valueProp(val) {
      this.value = val;
    },
    checkedProp(val) {
      this.checked = val;
    }
  }
}
</script>

<style lang="scss">
.zq--checkbox--wrap {
  .zq--checkbox {
    display: flex;
    align-items: center;
  }

  .toggle__text {
    line-height: normal;
  }

  .toggle__label {
    display: flex;
    align-items: center;
    margin-left: 0.2rem;
  }
}
</style>