import { getRadius } from './radius';
import * as d3 from 'd3';

const createCustomSVG = () => {
  const customSVG = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  customSVG.setAttribute('width', '38');
  customSVG.setAttribute('height', '37');
  customSVG.setAttribute('viewBox', '0 0 38 37');

  const path1 = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path1.setAttribute('d', 'M18.8228 18.3868C25.1754 8.27995 30.4718 0.178131 30.4718 0.178131C30.4718 0.178131 25.4644 8.46153 19.1118 18.5683C12.7593 28.6726 7.46289 36.7744 7.46289 36.7744C7.46289 36.7744 12.4703 28.491 18.8228 18.3868Z');
  path1.setAttribute('fill', 'white');

  const path2 = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path2.setAttribute('d', 'M18.8792 18.622C8.77235 12.2695 0.667969 6.97312 0.667969 6.97312C0.667969 6.97312 8.95136 11.9805 19.0582 18.3356C29.165 24.6882 37.2668 29.982 37.2668 29.982C37.2668 29.982 28.9834 24.9746 18.8792 18.6195V18.622Z');
  path2.setAttribute('fill', 'white');

  customSVG.appendChild(path1);
  customSVG.appendChild(path2);

  return customSVG;
};

export const createDotElements = (svg, borderContainer, isPreviewSpin) => {
  const numberOfDots = 16; // Adjust the number of dots as needed
  const radius = getRadius();

  const dotData = d3.range(numberOfDots);

  // Create a Radial Gradient
  const radialGradient = svg.append("defs")
    .append("radialGradient")
    .attr("id", "blur-gradient")
    .attr("cx", "50%")
    .attr("cy", "50%")
    .attr("r", "50%")
    .attr("fx", "50%")
    .attr("fy", "50%");

  // Adding Gradient Stops
  radialGradient.append("stop")
    .attr("offset", "0%")
    .attr("stop-color", "#ffffff")
    .attr("stop-opacity", "1");

  radialGradient.append("stop")
    .attr("offset", "100%")
    .attr("stop-color", "#ffffff")
    .attr("stop-opacity", "0");

  borderContainer
    .selectAll('.dot')
    .data(dotData)
    .enter()
    .append('circle')
    .attr('class', 'dot')
    .attr('cx', (d, i) => {
      const angle = (i / numberOfDots) * 360;
      return (radius + 16) * Math.cos((angle - 75) * (Math.PI / 180));
    })
    .attr('cy', (d, i) => {
      const angle = (i / numberOfDots) * 360;
      return (radius + 16) * Math.sin((angle - 75) * (Math.PI / 180));
    })
    .attr('r', isPreviewSpin ? 24 : 20)
    .attr('fill', 'url(#blur-gradient)')
    .attr('stroke-width', 2);
}

export const removeDotElements = () => {
  d3.selectAll('.dot').remove()
}