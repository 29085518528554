<template>
  <div class="ach-from-template">
    <!--  Header    -->
    <div>
      <LocalBreadcrumb
        v-if="!isNoTitle"
        :pageNumber="pageNumber"
        :items="[texts.createPage.rewards.breadcrumbRewards, texts.createPage.rewards.breadcrumbAddFrom]"
        :toolTipText="descriptions.addFromTemplate"
        @setCurrentPage="setPage"
      />
      <div class="zq--table-header">
        <div class="zq--table-header-search">
          <ClSearch
            placeholder="tableHeader.search"
            @searchUpdate="searchData"
          />
        </div>
        <div class="mb-1 zq--header-actions">
          <CButton
            size="sm"
            class="header-icon-buttons mr-3"
            @click="handleReload"
          >
            <CIcon name="cil-reload" />
          </CButton>
          <ClFilter
            :isFlag="false"
            @toggleFilter="toggleFilter"
          />
          <CogFields
            :originalFields="originalFields"
            :fields="fields"
            @updateFields="toggleColumn"
            @clearFilter="clearFilter"
          />
          <ClCustomButton
            @click="closeTemplatesTable"
            :text="$t('buttons.goBack')"
            class="mr-2 mb-1"
          />
          <ClResponsiveButton :label="$t('buttons.add')" @handler="save"/>
        </div>
      </div>
    </div>
    <!--  Header    -->
    <CRow class="mt-3 mr-0">
      <CCol class="pr-0">
        <CCard class="zq-card-table">
          <!--    CARD HEADER      -->
          <CCardHeader v-if="valid === false">
            <span class="table-error">{{ texts.createPage.rewards.select }}</span>
          </CCardHeader>
          <CCardBody>
            <!--  Table  -->
            <CDataTable
              id="rewardsTable"
              class="zq--table"
              ref="dataTableRef"
              :items="rewards"
              :class="{ 'is-show-filter': columnFilter }"
              :column-filter="columnFilter"
              :searchableAllFields="searchableAllFields"
              :fields="customFields"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="loading"
              @pagination-change="itemsPerPageSelect"
              @page-change="paginationChange"
              @update:column-filter-value="handleColumnFilter"
            >
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <template #period="{item}">
                <td>{{ item.period }}</td>
              </template>
              <template #metadata="{item}">
                <td>{{ getMetaData(item.metadata) }}</td>
              </template>
              <template #rewardType="{ item }">
                <td>{{ item.rewardType.key }}</td>
              </template>
              <!--            Select-->
              <template #select="{ item }">
                <td :data-id="item.id" class="text-center select-td fixed-column-start">
                  <ClCheckbox
                    :valueProp="item.id"
                    :checkedProp="selectedRewards.indexOf(item.id) > -1"
                    @handler="checkRewards"
                  />
                </td>
              </template>
              <template #select-filter>
                <div></div>
              </template>
            </CDataTable>
            <!-- Table Footer   -->
            <TableFooter
              :page="page"
              @updatePagenation="paginationChange"
              :pages="pages"
              :total="resultCount"
              :itemsPerPage="itemsPerPage"
              @updateItemPerPage="itemsPerPageSelect"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import TableFooter from '@/components/table/Footer';
import LocalBreadcrumb from '@/shared/components/LocalBreadcrumb';
import {getMetaData, dateFormate} from '@/utils';
import {pageConfig} from '@/config';
import {cloneDeep, uniqBy, difference} from "lodash";
import ClSearch from "@/shared/components/formComponents/ClSearch";
import ClResponsiveButton from "@/shared/components/formComponents/ClResponsiveButton";
import ClCheckbox from "@/shared/components/formComponents/ClCheckbox";
import {achievements} from "@/config/descriptions/achievements";
import {achievementsTexts} from "@/config/pageTexts/achievements.json";
import ClFilter from '@/components/header/ClFilter';
import CogFields from '@/components/header/CogFields';
import ClCustomButton from "@/shared/components/formComponents/ClCustomButton.vue";

export default {
  name: 'AddFromTemplate',
  components: {
    ClCustomButton,
    TableFooter,
    LocalBreadcrumb,
    ClSearch,
    ClCheckbox,
    ClResponsiveButton,
    ClFilter,
    CogFields,
  },
  props: {
    rewardPropsData: Array,
    entityType: String,
    pageNumber: {
      type: [String, Number],
      default: "5"
    },
    isNoTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      valid: null,
      query: [{
        "queryFields": ["tags"],
        "queryValue": "template"
      }],
      must: [{
        queryField: 'entityType',
        queryValues: [this.entityType]
      }],
      multiFields: [],
      search: '',
      toggleFlag: false,
      customFields: [],
      sortBy: pageConfig.sortBy,
      itemsPerPage: pageConfig.itemsPerPage,
      page: pageConfig.page,
      selectedRewards: [],
      rewardsData: [],
      descriptions: {
        addFromTemplate: achievements.list.create.rewards.addFromTemplate.addFromTemplate,
      },
      columnFilter: false,
      isFlag: false,
      filterFields: ['actions', 'spaceName']
    }
  },
  computed: {
    ...mapGetters('rewards', ['rewards', 'loading', 'message', 'pages', 'resultCount', 'fields', 'searchableAllFields', 'originalFields']),
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('rewards', ['handleGetRewardsByQuery', 'handleClearFilter', 'handleFields']),
    initialize() {
      this.handleGetRewardsByQuery({
        queryRequest: {
          must: this.must,
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }
      });
      this.selectedRewards = this.rewardPropsData.map(item => item.id);
      this.rewardsData = this.rewardPropsData;
      // this.selectedRewards.length === this.rewards.length ? this.toggleFlag = true : this.toggleFlag = false;
    },
    dateFormate(val) {
      return dateFormate(val);
    },
    getMetaData(items) {
      return getMetaData(items);
    },
    async searchData(value) {
      if (value.length > 2) {
        let multiFields = [];
        if (value.trim()) {
          multiFields.push({
            queryFields: this.searchableAllFields,
            queryValue: value
          })
        }
        await this.handleGetRewardsByQuery(
          {
            queryRequest: {
              must: this.must,
              multiFields: multiFields,
              sortBy: this.sortBy,
              limit: this.itemsPerPage,
              skip: (this.page - 1) * this.itemsPerPage
            }
          }
        );
      } else if (value.length === 0) {
        this.handleGetRewardsByQuery({
          queryRequest: {
            must: this.must,
            sortBy: this.sortBy,
            skip: (this.page - 1) * this.itemsPerPage,
            limit: this.itemsPerPage,
          }
        });
      }
    },
    resetSearch() {
      this.searchData(null)
    },
    itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.handleGetRewardsByQuery(this.query);
    },
    paginationChange(val) {
      this.page = val;
      this.handleGetRewardsByQuery({
        queryRequest: {
          must: this.must,
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }
      });
    },
    checkRewards({event}) {
      const index = this.selectedRewards.indexOf(event.target.value);

      const reward = this.rewards.find(item => item.id === event.target.value);

      if (index > -1) {
        this.selectedRewards = [];
        this.rewardsData = [];
      } else {
        this.selectedRewards = [event.target.value];
        this.rewardsData = [reward];
      }

    },
    selectAll() {
      const rewards = cloneDeep(this.rewards);
      this.toggleFlag = !this.toggleFlag;
      if (this.toggleFlag) {
        rewards.forEach(item => this.selectedRewards.push(item.id));
        this.rewardsData = [...this.rewardsData, ...rewards]
      } else {
        // rewards of current page
        const df = difference(this.rewards, this.rewardPropsData).map(i => i.id);
        // rewards from 'Reward creation'
        const rewardsFromCreation = this.rewardsData.filter(item => item.id.startsWith('nxt_'));

        this.selectedRewards = this.selectedRewards.filter(item => {
          if (!df.includes(item)) {
            return item
          }
        })
        // remaining selected Rewards
        const updatedData = this.rewardPropsData.filter(item => {
          if (this.selectedRewards.includes(item.id)) {
            return item
          }
        })

        this.rewardsData = [...rewardsFromCreation, ...updatedData];
      }
    },
    save() {
      if (this.rewardsData.length) {
        let transformedRewards = [];
        this.rewardsData.forEach(reward => {
          let tempObject = {};
          for (const [key, value] of Object.entries(reward)) {
            if (key === 'constraints') {
              tempObject.addConstraints = value;
            } else {
              tempObject[key] = value;
            }
          }
          transformedRewards.push(tempObject);
        })
        this.$emit('updateRewardsData', uniqBy(transformedRewards, 'id'));
        this.$emit('setPage', {page: 'reward creation'});

        this.valid = null;
      }
      this.valid = false;
    },
    closeTemplatesTable() {
      this.$emit('setPage', {page: 'rewards'})
    },
    setPage() {
      this.$emit('setPage', {page: 'reward creation'})
    },
    handleReload() {
      this.page = 1;
      this.handleGetRewardsByQuery({
        queryRequest: {
          must: this.must,
          sortBy: this.sortBy,
          skip: 0,
          limit: this.itemsPerPage,
        }
      });
    },
    toggleFilter(val) {
      this.isFlag = !this.isFlag;
      this.columnFilter = this.isFlag;
      if (!val) {
        this.formatFilter();
      }
    },
    async formatFilter() {
      this.$refs.dataTableRef.clean();
      this.page = 1;
      await this.handleGetRewardsByQuery({
        queryRequest: {
          must: this.must,
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }
      });
    },
    async handleColumnFilter(e) {
      const columnFilterName = Object.keys(e)[Object.keys(e).length - 1];
      this.multiFields = [];
      let str = "";
      Object.keys(e).forEach((x) => {
        if (e[x] !== ' ') {
          str = e[x].trim();
          if (str.length > 0) {
            this.sortBy = [
              {
                queryField: '_score',
                order: 'Desc',
              },
            ];
            this.multiFields.push({
              queryFields: [x],
              queryValue: str,
            });
          } else {
            return false;
          }
        }
      });
      await this.queryDB();

      this.$refs.dataTableRef.$el
        .querySelector('[aria-label="column name: \'' + columnFilterName +'\' filter input"]')
        .focus();
    },
    async queryDB() {
     await this.handleGetRewardsByQuery({
       queryRequest:
          {
            must: this.must,
            multiFields: this.multiFields,
            sortBy: this.sortBy,
            limit: this.itemsPerPage,
            skip: (this.page - 1) * this.itemsPerPage
          }
     });
    },
    clearFilter() {
      this.handleClearFilter();
    },
    async toggleColumn(val) {
      await this.handleFields(val);
    },
  },
  watch: {
    rewards: {
      deep: true,
      handler(val) {
        const df = difference(val, this.rewardPropsData);
        df.every(item => this.selectedRewards.includes(item.id)) ? this.toggleFlag = true : this.toggleFlag = false;
      }
    },
    fields(val) {
      this.customFields = val.filter(field => !this.filterFields.includes(field));
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";
.zq--table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include media-breakpoint-down('576px') {
    flex-direction: column;
  }
}
.zq--header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //flex: 1;
  @include media-breakpoint-down('576px') {
    flex-wrap: wrap;
    //width: 100%;
  }
}
.zq--table-header-search {
  display: flex;
  align-items: center;
  //flex: 2;
  flex-wrap: wrap;
  @include media-breakpoint-down('576px') {
    //width: 100%;
    justify-content: space-between;
  }
  .showDropdown {
    @media screen and (max-width: 1720px) {
      display: block !important;
    }
  }
  .hideButtons {
    @media screen and (max-width: 1720px) {
      display: none !important;
    }
  }
}
.ach-from-template {
  .ach-from-template--actions-header {
    min-height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-breakpoint-down('304px') {
      justify-content: flex-end;
      .search-container {
        margin-bottom: .8rem;
      }
    }
  }

  .bread {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .table-error {
    color: darkred;
  }
}

#rewardsTable .table th, .table td {
  padding-left: 1rem;
}

#rewardsTable {
  .table {

    td:last-child {
      &:after {
        right: 16px;
      }

      &:before {
        right: 16px;
      }
    }
  }
}
</style>