<template>
  <div class="range-slider-wrap">
    <vue-slider
        v-model="value"
        :min="min"
        :max="max"
        class="w-100"
        :marks="marks"
        :disabled="disabled"
    />
    <span class="ml-2 range-slider--max">max</span>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  components: {
    VueSlider
  },
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 80
    },
    valueProp: Array,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: [0, 40],
      marks: val => val % 10 === 0
    }
  },
  created() {
    this.value = this.valueProp;
  },
  watch: {
    value(val) {
      this.$emit('update', val)
    }
  }
}
</script>

<style lang="scss">
.range-slider-wrap {
  display: flex;
  min-width: 130px;
  margin-bottom: 1.2rem;
}
</style>