export const achievementQuery = {
    "achievement.points": {
        "type": "condition",
        "mustMatchAll": true,
        "mustEvaluateTo": true,
        "scope": "",
        "operand": "",
        "operator": "",
        "value": "",
        "rules": [],
        "then": [
            {
                "routine": "",
                "arguments": [],
                "lineNumber": 2
            }
        ],
        "lineNumber": 1
    },
};