import * as d3 from 'd3';
import { getRadius } from './radius';
import { generatePieData } from './generatePieData';

export const createWheelSections = (
  svg,
  radius,
  sectionsCount,
  getSectionFill,
  getSectionStroke,
  getSectionStrokeWidth,
  handleSectionClick,
  isPreviewSpin
) => {
  const pieData = generatePieData(
    radius,
    sectionsCount,
    getSectionFill,
    getSectionStroke,
    getSectionStrokeWidth
  );

  const wheel = svg.select('.wheel-group');

  const sectionsGroup = wheel.append('g').selectAll('.path-section')
    .data(pieData)
    .enter()
    .append('path')
    .attr('class', 'path-section')
    .attr('id', (d) => `path-section-${ d.id }`)
    .attr('d', (d) => d.arc)
    .attr('fill', (d) => d.fill)
    .attr('stroke', (d) => d.stroke)
    .attr('stroke-width', (d) => d.strokeWidth)
    .on('click', (d, i) => {
      if (!isPreviewSpin) handleSectionClick(i.id);
    });

  sectionsGroup.each(function (d) {
    d3.select(this)
      .attr('stroke', d.stroke)
      .attr('stroke-width', d.strokeWidth);
  });
};

export const updateSelectedSection = (sectionId, sectionsCount) => {
  const anglePerSection = 360 / sectionsCount
  const i = Number(sectionId) - 1
  const selectedAngle = 360 - i * anglePerSection - anglePerSection / 2

  const wheel = d3.selectAll('.wheel-group')
  const radius = getRadius()

  wheel
    .transition()
    .duration(1000)
    .attr('transform', `translate(${radius + 4},${radius + 4}) rotate(${selectedAngle})`)

  return sectionId
};