<template>
  <CRow class="entrants-detail">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ texts.summary.entrants.title }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <!--       Opt in require     -->
            <ClCardRow :label="texts.summary.entrants.optLabel" :content="`${data.optinRequiredForEntrants}`" />
            <!--      Limit      -->
            <ClCardRow :label="texts.summary.entrants.everyLabel" v-if="data.limitParticipants">
              <template #content>
                <RangeSlider
                  :valueProp="[data.formData.minNumberOfEntrants, data.formData.maxNumberOfEntrants]"
                  :disabled="true"
                />
              </template>
            </ClCardRow>
            <!--      Should Match At Least      -->
            <ClCardRow
              v-if="data.formData.should.length >= 2"
              :label="texts.summary.entrants.shouldMatchLabel"
              :content="data.formData.shouldMatchAtLeast"
            />
            <!--       TABLE     -->
            <CDataTable
              v-if="entrantsData.selectedData.length"
              id="achTableResult"
              class="zq--table zq--table--default-pagination"
              :items="entrantsData.selectedData"
              :fields="fields"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              pagination
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
            >
              <!--      CREATED         -->
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <!--      ID         -->
              <template #id="{ item }" v-if="isPreview">
                <td>
                  <router-link :to="{name: `PreviewMember`, params: {id: item.id} }">
                    {{ item.id }}
                  </router-link>
                </td>
              </template>
              <!--       Description           -->
              <template #description="{ item }">
                <td>
                  <span v-html="item.description"></span>
                </td>
              </template>
              <!--       Dependency           -->
              <template #dependency="{ item }">
                <td>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="action-buttons-list">
                      <button
                        class="action-buttons action-buttons__must-not"
                        :class="{'action-buttons--active__must-not': data.formData.mustNot.includes(item.key)}"
                      >
                        {{ texts.summary.entrants.mustNotText }}
                      </button>
                      <button
                        class="action-buttons action-buttons__should"
                        :class="{'action-buttons--active__should': data.formData.should.includes(item.key)}"
                      >
                        {{ texts.summary.entrants.shouldText }}
                      </button>
                      <button
                        class="action-buttons action-buttons__must"
                        :class="{'action-buttons--active__must': data.formData.must.includes(item.key)}"
                      >
                        {{ texts.summary.entrants.mustText }}
                      </button>
                    </div>
                  </div>
                </td>
              </template>
            </CDataTable>
            <!--       All Can Participate      -->
            <ClCardRow
              v-if="!entrantsData.selectedData.length"
              :label="texts.summary.entrants.memberGroupsLabel"
            >
              <template #content>
                <strong class="group__colored">{{ texts.summary.entrants.everyoneText }}</strong>
              </template>
            </ClCardRow>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import RangeSlider from '@/shared/components/RangeSlider';
import {pageConfig} from '@/config';
import {dateFormate} from "@/utils";
import ClCardRow from "@/shared/UI/ClCardRow";
import ClCardArrow from "@/shared/UI/ClCardArrow";
import {mapGetters} from "vuex";
import {competitionsTexts} from "@/config/pageTexts/competitions.json";

export default {
  name: 'EntrantsTags',
  components: {
    RangeSlider,
    ClCardRow,
    ClCardArrow
  },
  props: {
    entrantsData: Object,
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      texts: {
        ...competitionsTexts
      },
      page: pageConfig.page,
      itemsPerPage: pageConfig.itemsPerPage,
      basicIsCollapsed: false,
      data: {},
      //TODO: change when API done
      fields: [
        "key",
        "created",
        "memberRefId",
        "name",
        "dependency"
      ],
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  created() {
    this.data = this.entrantsData;
  },
  methods: {
    dateFormate(val) {
      return dateFormate(val);
    },
  },
  watch: {
    entrantsData: {
      deep: true,
      handler(val) {
        this.data = val;
      }
    }
  }
}
</script>

<style lang="scss">
.entrants-detail {
  .group {
    background: #2eb85c;
    color: #ffffff;
    padding: 0.3rem;
    border-radius: 4px;
  }
}

#achTableResult {
  box-shadow: none;
}
</style>

