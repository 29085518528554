export const isAchFieldDisabled = (status) => {
    if (status === 'Draft') return false;
    return status === 'Live';
}

export const isCompFieldDisabled = (status) => {
    status = status ?? 'Ready';
    return !(status === 'Ready');
}


export const metadataValidations = (metadata) => {
    if (metadata.length && metadata[0].key) {
        return metadata[0].key.length < 151;
    } else {
        return true;
    }
}

// TEXT VALIDATION OPTIONS
const textValidationOptions = {
    length: 100,
    message: null
}

export const textValidation = (text, options = textValidationOptions) => {
    let message = '';

    if (options.message) {
        message = options.message;
    } else if (!text.trim()) {
        message = 'Required';
    } else if (text.trim().length >= options.length) {
        message = `valid length is under ${options.length} characters`
    }

    if (text.trim() && text.trim().length <= options.length) {
        return {
            valid: true,
            message: null
        };
    } else {
        return {
            valid: false,
            message
        };
    }
}