<template>
  <CRow class="rewards-summary">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ title }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <CDataTable
              id="rewardsResultTable"
              class="zq--table"
              :items="rewardsData"
              :fields="customFields"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
            >
              <template #metadata="{item}">
                <td>{{ getMetaData(item.metadata) }}</td>
              </template>
              <template #created="{ item }">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <template #rewardType="{ item }">
                <td>{{ item.rewardType.key }}</td>
              </template>
              <template #period="{item}">
                <td>{{ item.period }}</td>
              </template>
              <template #description="{item}">
                <td v-html="item.description "></td>
              </template>
              <template #icon="{item}">
                <td>{{ item.icon }}</td>
              </template>
              <template #translatableFields="item">
                <td>
                  {{ getTranslatableFields(item) }}
                </td>
              </template>
              <template #Expires="{item}">
                <td>{{ dateFormate(item.pointInTime) }}</td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {getMetaData, getTranslatableFields} from '@/utils';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import {achievementsTexts} from "@/config/pageTexts/achievements.json";
import {dateFormate} from '@/utils/dateFormate';

export default {
  name: 'Rewards',
  components: {
    ClCardArrow
  },
  props: {
    rewardsData: Array,
    title: {
      type: String,
      default: achievementsTexts.createPage.summary.rewards.title
    }
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      basicIsCollapsed: false,
      data: [],
      customFields: [],
      hiddenFields: [
        'select',
        'actions',
        'spaceName',
        'constraints',
        'customFields',
        'tags',
        'metadata',
        'created',
        'entityType',
        'entityId',
      ],
      tableFields: ['name', 'rewardValue', 'rewardType', 'Expires'],
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('rewards', ['fields']),
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  mounted() {
    this.customFields = this.tableFields.filter(field => {
      return !this.hiddenFields.includes(field);
    });
  },
  methods: {
    ...mapActions('rewardTypes', ['handleGetRewardTypes']),
    getMetaData(items) {
      return getMetaData(items)
    },
    getTranslatableFields({item}) {
      return getTranslatableFields(item);
    },
    dateFormate(val) {
      return dateFormate(val)
    },
  }
}
</script>

<style lang="scss">
#rewardsResultTable {
  box-shadow: none;
}
</style>