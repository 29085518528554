import * as d3 from 'd3';

export const createSVG = (radius, isPreviewSpin) => {
  const viewBox = isPreviewSpin ? `-50 -10 ${ radius * 2 + 180 } ${ radius * 2 + 10 }` : `-40 -10 ${ radius * 2 + 96 } ${ radius * 2 + 26 }`;
  const width = isPreviewSpin ? `${ radius * 2 - 55 }` : `${ radius * 2 + 40 }`;
  const height = isPreviewSpin ? `${ radius * 2 - 55 }` : `${ radius * 2 + 40 }`;

  const svg = d3.select('#wheel-container')
    .append('svg')
    .attr('viewBox', viewBox)
    .attr('preserveAspectRatio', 'xMidYMid meet')
    .attr('width', width)
    .attr('height', height);

  const defs = svg.append('defs');

  return { svg, defs };
};

export const createWheelGroup = (svg, radius) => {
  return svg.append('g')
    .attr('class', 'wheel-group')
    .attr('transform', `translate(${ radius + 4 },${ radius + 4 })`);
};

export const createArrowGroup = (svg, radius) => {
  return svg.append('g')
    .attr('class', 'arrow-group')
    .attr('transform', `translate(${ radius + 4 },${ radius + 4 })`);
};