<template>
  <div class="rewards form-content content">
    <!--  Create Section   -->
    <CreateReward
      v-if="currentPage.page === 'reward creation'"
      @createReward="createReward"
      @cancelReward="cancelReward"
      :fileData="currentPage.data"
      :pageNumber="pageNumber"
      :isRewardRankField="isRewardRankField"
      :rewardData="templateRewardData"
      @setPage="setPage"
      :isNoTitle="isNoTitle"
      :entityModel="model"
    />
    <!-- Add From Template   -->
    <AddFromTemplate
      v-if="currentPage.page === 'addFromTemplate' && !isTemplatesModal"
      @updateRewardsData="updateRewardsData"
      :rewardPropsData="selectedRewards"
      :pageNumber="pageNumber"
      @setPage="setPage"
      :entityType="entityType"
      :isNoTitle="isNoTitle"
    />
    <!--  Table Section  -->
    <TableRewards
      @createNewReward="createNewReward"
      @addFromTemplate="addFromTemplate"
      :rewardData="selectedRewards"
      v-on:updateRewards="updateRewards"
      @updateRewardsData="updateRewardsData"
      @setPage="setPage"
      v-if="currentPage.page === 'rewards'"
      :disabled="isFieldDisabled"
      :pageNumber="pageNumber"
      :loading="loading"
      :chosenRewards="selectedRewards"
      :isNoTitle="isNoTitle"
    />
    <!--  PreviewReward  -->
    <PreviewReward
      v-if="currentPage.page === 'preview reward'"
      backPathOrigin="rewards"
      :rewardData="currentPage.data"
      :pageNumber="pageNumber"
      @setPage="setPage"
    />
    <RewardTemplatesModal
      :isShowRewardTemplatesModal="currentPage.page === 'addFromTemplate' && isTemplatesModal"
      @selectReward="updateRewardsData"
      @closeModal="closeModal"
      @setPage="setPage"
      :entityType="entityType"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CreateReward from './components/CreateReward';
import TableRewards from './components/TableRewards';
import AddFromTemplate from './components/AddFromTemplate';
import PreviewReward from './components/PreviewReward';
import {pageConfig} from "@/config";
import {isCompFieldDisabled, isAchFieldDisabled} from "@/utils/validationUtils";
import {cloneDeep, uniqueId} from "lodash";
import RewardTemplatesModal from '@/views/ziqni/instant-wins/RewardTemplatesModal';

export default {
  name: 'CreateRewards',
  components: {
    RewardTemplatesModal,
    CreateReward,
    TableRewards,
    AddFromTemplate,
    // Repositories,
    // Files,
    // PreviewFile,
    PreviewReward,
  },
  props: {
    rewardsData: Array,
    entityType: String,
    isRewardRankField: {
      type: Boolean,
      default: true
    },
    pageNumber: {
      type: [String, Number],
      default: "5"
    },
    pageType: {
      type: String,
      default: 'Ach'
    },
    status: {
      type: String,
      default: 'Ready'
    },
    isNoTitle: {
      type: Boolean,
      default: false
    },
    isCreatingDisabled: {
      type: Boolean,
      default: true
    },
    model: {
      type: String,
      default: ''
    },
    isTemplatesModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      query: {
        "should": [
          {
            "queryFields": ["tags"], "queryValue": "template"
          }
        ]
      },
      sortBy: pageConfig.sortBy,
      itemsPerPage: pageConfig.itemsPerPage,
      page: pageConfig.page,
      currentPage: {page: 'rewards', data: {}},
      rewardData: [],
      selectedRewards: [],
      loading: false,
      isSelectTableVisible: false,
      templateRewardData: {},
      isSaved: true,
    }
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('rewards', ['rewards']),
    isFieldDisabled() {
      if (this.pageType === 'Ach') {
        return isAchFieldDisabled(this.status)
      }
      return isCompFieldDisabled(this.status);
    },
    previewFileBreadcrumbs() {
      if (this.currentPage.data.from) {
        return ['Rewards', 'Reward creation', 'Preview File']
      } else {
        return ['Rewards', 'Reward creation', 'Repositories', 'Files', 'Preview File']
      }
    },
  },
  methods: {
    ...mapActions('rewards', ['handleFields', 'handleCreateRewards', 'handleGetRewards_item', 'handleGetRewardsByQuery']),
    ...mapActions('rewardTypes', ['handleGetRewardTypes_item']),
    async initialize() {
      await this.getRewards();
      if (this.rewardsData) {
        this.selectedRewards = cloneDeep(this.rewardsData);
        this.selectedRewards = this.selectedRewards.map(reward => {
          if (!reward.id) {
            reward.id = uniqueId('nxt_');
          }

          return reward;
        })
      }
      this.handleFields();
    },
    async getRewards() {
      await this.handleGetRewardsByQuery({
        queryRequest: {
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }
      });
    },
    createNewReward() {
      this.currentPage.page = 'reward creation';
      this.isSaved = false;
      this.$emit('isRewardSaved', this.isSaved);
    },
    addReward(selectedRewards) {
      this.selectedRewards = selectedRewards.rewards;
      this.$emit('updateRewardData', selectedRewards);
    },
    async createReward(val) {
      const idx = this.selectedRewards.findIndex(r => r.id === val.id);
      if (idx !== -1) {
        this.selectedRewards[idx] = val;
      } else {
        this.selectedRewards.push(val);
      }
      this.currentPage.page = 'rewards';
      this.isSaved = true;
      this.$emit('isRewardSaved', this.isSaved);
      this.templateRewardData = {};
      this.$emit('updateRewardData', this.selectedRewards);
    },
    addFromTemplate() {
      this.currentPage.page = 'addFromTemplate';
    },
    closeModal() {
      this.currentPage.page = 'rewards';
    },
    cancelReward() {
      this.templateRewardData = {};
      this.currentPage.page = 'rewards';
      this.isSaved = true;
      this.$emit('isRewardSaved', this.isSaved);
    },
    cancelEditReward() {
      this.currentPage = 'rewards';
    },
    updateRewards(val) {
      this.selectedRewards = val;
    },
    setPage(val) {
      if (val && val.page === 'reward creation') {
        this.isSaved = false;
        this.$emit('isRewardSaved', this.isSaved);
      }
      this.currentPage = val;
    },
    async updateRewardsData(val) {
      if (this.isTemplatesModal) {
        this.templateRewardData = val;
      } else {
        const rewardType = await this.handleGetRewardTypes_item([val[0].rewardType.id], 1, 0);
        const rewardData = cloneDeep(val[0]);
        rewardData.rewardType = rewardType[0];
        this.templateRewardData = rewardData;
      }
    },
    addIcon(val) {
      this.$emit('addRewardIcon', val);
      this.currentPage = {page: 'reward creation', data: {id: val}}
    },
  },
  watch: {
    rewards: {
      deep: true,
      handler: function (val) {
        if (val && val.length) {
          this.rewardData = val;
        }
      },
    },
    selectedRewards: {
      deep: true,
      handler: function (val) {
        this.$emit('updateRewardData', val);
      },
    },
  }
}
</script>

<style lang="scss">
.rewards {
  height: 100%;
}
</style>
