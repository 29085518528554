import * as d3 from 'd3';
import { getRadius } from './radius';

export const createArrowPointer = (arrowGroup, isPreviewSpin) => {
  const radius = getRadius();

  const translate = isPreviewSpin
    ? `translate(-27, -${ (radius) + 1 })`
    : `translate(-16, -${ (radius) + 11 })`;

  arrowGroup
    .append('g')
    .attr('class', 'arrow-pointer')
    .attr('transform', translate)
    .append('svg')
    .attr('x', '0px')
    .attr('y', '0px')
    .attr('width', () => isPreviewSpin ? '69px' : '40px')
    .attr('height', () => isPreviewSpin ? '52px' : '47px')
    .attr('viewBox', '0 0 69 52')
    .attr('xmlns', 'http://www.w3.org/2000/svg')
    .append('g')
    .attr('clip-path', 'url(#clip0_199_35821)')
    .append('path')
    .attr('d', 'M50.7644 3.95196V1.44899H3.63867V4.00637L25.6557 41.8229C26.1241 42.7479 28.3727 42.7479 28.9348 41.8229L50.8581 3.95196H50.7644Z')
    .attr('fill', '#D98A36');

  arrowGroup
    .select('svg')
    .append('g')
    .append('path')
    .attr('class', 'arrow-pointer-path')
    .attr('d', 'M3.63867 1.23114L25.6557 39.9727C26.1241 40.8977 28.3727 40.8977 28.9348 39.9727L50.8581 1.23114C35.6804 0.197303 19.8469 0.251715 3.63867 1.23114Z')
    .attr('fill', 'black');

  arrowGroup
    .select('svg')
    .append('g')
    .append('path')
    .attr('d', 'M26.8736 39.9729L4.85663 1.23134H3.63867L25.6557 39.9729C26.0304 40.5714 27.061 40.7347 27.9042 40.5714C27.4358 40.4626 27.061 40.2994 26.8736 39.9729Z')
    .attr('fill', 'url(#paint0_linear_199_35821)');
};

export const removeArrowPointer = () => {
  d3.selectAll('.arrow-pointer').remove();
  d3.selectAll('.arrow-image').remove();
};

export const createArrowPointerWithImage = async (arrowGroup, isPreviewSpin, arrowImgUri) => {
  const radius = getRadius();

  const arrowImage = await loadImage(arrowImgUri);

  const imageSize = {
    width: isPreviewSpin ? arrowImage.width / 2 : arrowImage.width / 3,
    height: isPreviewSpin ? arrowImage.height / 2 : arrowImage.height / 3
  };

  const correction = imageSize.height / 4;

  const translateX = isPreviewSpin
    ? -imageSize.width / 2
    : -imageSize.width / 2;
  const translateY = isPreviewSpin
    ? -radius - imageSize.height / 2 - correction
    : -radius - imageSize.height / 2 - correction;

  let arrowImageElement = arrowGroup.select('.arrow-image');

  if (arrowImageElement.size() > 0) {
    arrowImageElement.attr('href', arrowImgUri);
  } else {
    arrowImageElement = arrowGroup
      .append('image')
      .attr('class', 'arrow-image')
      .attr('href', arrowImgUri);
  }

  arrowImageElement
    .attr('width', imageSize.width)
    .attr('height', imageSize.height)
    .attr('transform', `translate(${ translateX },${ translateY })`);
};


function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(this);
    };
    img.onerror = function () {
      reject(new Error('Failed to load image'));
    };
    img.src = url;
  });
}
