var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"rewards-summary"},[_c('CCol',{attrs:{"col":"12"}},[_c('CCard',{staticClass:"zq--wizard-card"},[_c('CCardHeader',{on:{"click":function($event){_vm.basicIsCollapsed = !_vm.basicIsCollapsed}}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('strong',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('CLink',{staticClass:"card-header-action btn-minimize"},[_c('ClCardArrow',{attrs:{"is-collapsed":_vm.basicIsCollapsed}})],1)],1)]),_c('CCollapse',{attrs:{"show":_vm.basicIsCollapsed,"duration":400}},[_c('CCardBody',[_c('CDataTable',{staticClass:"zq--table",attrs:{"id":"rewardsResultTable","items":_vm.rewardsData,"fields":_vm.customFields,"responsive":true,"border":_vm.isDefaultTheme,"hover":_vm.isDefaultTheme,"striped":_vm.isDefaultTheme},scopedSlots:_vm._u([{key:"metadata",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getMetaData(item.metadata)))])]}},{key:"created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.dateFormate(item.created)))])]}},{key:"rewardType",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.rewardType.key))])]}},{key:"period",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.period))])]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',{domProps:{"innerHTML":_vm._s(item.description )}})]}},{key:"icon",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.icon))])]}},{key:"translatableFields",fn:function(item){return [_c('td',[_vm._v(" "+_vm._s(_vm.getTranslatableFields(item))+" ")])]}},{key:"Expires",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.dateFormate(item.pointInTime)))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }