var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"products-summary"},[_c('CCol',{attrs:{"col":"12"}},[_c('CCard',{staticClass:"zq--wizard-card"},[_c('CCardHeader',{on:{"click":function($event){_vm.basicIsCollapsed = !_vm.basicIsCollapsed}}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('strong',{staticClass:"title"},[_vm._v(_vm._s(_vm.getTitle()))]),_c('CLink',{staticClass:"card-header-action btn-minimize"},[_c('ClCardArrow',{attrs:{"is-collapsed":_vm.basicIsCollapsed}})],1)],1)]),_c('CCollapse',{attrs:{"show":_vm.basicIsCollapsed,"duration":400}},[_c('CCardBody',[(!_vm.productsData.selectedData.length)?_c('ClCardRow',{attrs:{"label":_vm.texts.summary.products.productGroupsLabel},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('strong',{staticClass:"group__colored"},[_vm._v(_vm._s(_vm.texts.summary.products.everyoneText))])]},proxy:true}],null,false,1274083304)}):_vm._e(),(_vm.productsData.formData.dependantOn.should.length >= 2)?_c('ClCardRow',{attrs:{"label":_vm.texts.summary.products.shouldLabel,"content":_vm.productsData.formData.shouldMatchAtLeast}}):_vm._e(),(_vm.productsData.selectedData.length)?_c('CDataTable',{staticClass:"zq--table zq--table--default-pagination",attrs:{"id":"rewardsResultTable","items":_vm.productsData.selectedData,"fields":_vm.fields,"pagination":"","items-per-page":_vm.itemsPerPage,"responsive":true,"border":_vm.isDefaultTheme,"hover":_vm.isDefaultTheme,"striped":_vm.isDefaultTheme},scopedSlots:_vm._u([{key:"created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.dateFormate(item.created)))])]}},(_vm.isPreview)?{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{name: _vm.isTag ? 'PreviewTag' : "PreviewProduct", params: {id: item.id} }}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}}:null,{key:"tags",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.tags))])]}},{key:"dependency",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"action-buttons-list"},[_c('button',{staticClass:"action-buttons action-buttons__must-not",class:{'action-buttons--active__must-not': _vm.productsData.formData.dependantOn.mustNot.includes(item.key)}},[_vm._v(" "+_vm._s(_vm.texts.summary.products.mustNotText)+" ")]),_c('button',{staticClass:"action-buttons action-buttons__should",class:{'action-buttons--active__should': _vm.productsData.formData.dependantOn.should.includes(item.key)}},[_vm._v(" "+_vm._s(_vm.texts.summary.products.shouldText)+" ")]),_c('button',{staticClass:"action-buttons action-buttons__must",class:{'action-buttons--active__must': _vm.productsData.formData.dependantOn.must.includes(item.key)}},[_vm._v(" "+_vm._s(_vm.texts.summary.products.mustText)+" ")])])])])]}}],null,true)}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }