<template>
  <CRow class="zq-steps-header">
    <CCol v-for="(step, index) in steps" :key="index" class="px-0">
      <div
        variant="ghost"
        class="nav-pills"
        :class="{ active: index === currentStep, 'nav-pills__success':  currentStep > index || isCompleted}"
        block
        color="primary"
        @click="selectStep(index)"
      >
        <span
          v-if="currentStep > index"
          :class="{'step-index__success': currentStep > index}"
          class="step-index"
        >
          <i class="fa fa-check"></i>
        </span>
        <div class="step-index" v-else>{{ index + 1 }}</div>
        <div class="description ml-3 py-2">
          <span class="text-ellipsis overflow-hidden text-nowrap d-block zq--steps--title">
            {{ step.title.charAt(0).toUpperCase() + step.title.slice(1) }}
          </span>
          <small class="d-block overflow-hidden text-nowrap text-ellipsis zq--steps--sub-title">
            {{ step.subTitle }}
          </small>
        </div>
      </div>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'WizardHeader',
  props: {
    currentStep: Number,
    steps: Array,
    isCompleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      completedSection: false,
    };
  },
  methods: {
    selectStep(index) {
      if (this.currentStep > index || this.isCompleted) {
        this.$emit('updateCurrentStep', index);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";

.zq-steps-header {
  display: flex;
  padding: 0 1.5rem !important;
}

.nav-pills {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  color: #515151;
  &.active {
    background: #3399ff;
    color: #ffffff;
    border-radius: 12px;
    border-bottom-left-radius: 0;
  }
  svg {
    width: 30px;
    height: 30px;
    fill: #3399ff;
    color: #ffffff;
    &.step-index {
      border: 0;
      background: #3a99ff;
      color: #ffffff;
      fill: #ffffff;
      opacity: 1;
    }
  }
  .step-index {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0.5;
    @include media-breakpoint-down('360px') {
      width: 24px;
      height: 24px;
    }
  }
  .description {
    width: calc(100% - 60px);
    .text-ellipsis {
      text-overflow: ellipsis;
    }
    @include media-breakpoint-down('576px') {
      margin-left: 0 !important;
    }
  }
  @include media-breakpoint-up('768px') {
    &.nav-pills__success {
      cursor: pointer;
    }
  }
}
</style>
