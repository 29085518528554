var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',{staticClass:"mt-3"},[_c('CCol',{attrs:{"col":"12"}},[_c('CCard',{staticClass:"zq-card-table"},[_c('CCardBody',[_c('CDataTable',{ref:"dataTableRef",staticClass:"zq--table",attrs:{"id":"dataTableId","items":_vm.instantWinTileData,"fields":_vm.tableFields,"responsive":true,"border":false},scopedSlots:_vm._u([{key:"col",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.location.col))])]}},{key:"row",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.location.row))])]}},{key:"contraints",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.contraints))])]}},{key:"probability",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.probability ? item.probability : 'Auto'))])]}},{key:"reward",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.reward && Object.keys(item.reward).length !== 0)?_c('div',{staticClass:"reward"},[(_vm.$route.name === 'PreviewInstantWin' && item.reward['name'])?_c('span',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showReward(item.reward)}}},[_vm._v(" "+_vm._s(item.reward.name)+" ")])]):_vm._e(),(_vm.$route.name !== 'PreviewInstantWin')?_c('div',{staticClass:"reward-data"},[(item.reward['name'])?_c('span',[_vm._v("Name: "+_vm._s(item.reward.name))]):_vm._e(),(item.reward['rewardValue'])?_c('span',[_vm._v("Value: "+_vm._s(item.reward.rewardValue))]):_vm._e(),(item.reward['rewardType'])?_c('span',[_vm._v("Type: "+_vm._s(item.reward.rewardType.key))]):_vm._e()]):_vm._e()]):_vm._e()])]}},{key:"text",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.truncateAndClearText(item.text)))])]}}])})],1)],1)],1)],1),_c('Modal',{attrs:{"modalShow":_vm.isModal,"messageGeneral":_vm.modalText,"title":_vm.modalTitle,"isSuccessButton":false},on:{"toggle-modal":function($event){_vm.isModal = false},"closeModal":function($event){_vm.isModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }