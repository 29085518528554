<template>
  <CButton
      class="header-icon-buttons"
      :class="{'header-icon-buttons__active': isFlag}"
      @click="toggleFilter"
      variant="">
    <svg xmlns="http://www.w3.org/2000/svg" :width="iconSize" :height="iconSize" viewBox="0 0 24 24">
      <g>
        <g fill-rule="nonzero">
          <g>
            <g>
              <g>
                <path
                    d="M5.869 4H18.13c.553 0 1 .448 1 1 0 .197-.058.39-.167.555L14 13v4.382c0 .379-.214.725-.553.894l-2 1c-.494.247-1.094.047-1.341-.447-.07-.139-.106-.292-.106-.447V13L5.036 5.555c-.306-.46-.182-1.08.278-1.387.164-.11.357-.168.555-.168z"
                    transform="translate(-268 -308) translate(0 72) translate(256 224) translate(12 12)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

    <!--    <CIcon name="cil-filter-x" v-if="isFlag"  />-->
  </CButton>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    isFlag: Boolean
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    iconSize() {
      switch (this.theme) {
        case 'main': {
          return 24
        }
        case 'default': {
          return 16
        }
        default: {
          return 16
        }
      }
    }
  },
  methods: {
    toggleFilter() {
      this.$emit('toggleFilter')
    }
  }
}
</script>

<style lang="scss">
.header-icon-buttons {
  &__active.btn {
    color: #fff;
    background-color: #636f83;
    border-color: #636f83;
    fill: #fff
  }
}
</style>