<template>
  <CSwitch
      color="success"
      shape="pill"
      :value="value"
      :checked="value"
      class="zq--switch"
      :labelOn="labelOn"
      :labelOff="labelOff"
      :disabled="isFieldDisabled"
      @update:checked="update"
  />
</template>

<script>
export default {
  props: {
    initState: {
      type: Boolean,
      default: false
    },
    isFieldDisabled: Boolean,
    labelOn: {
      type: String,
      default: 'on'
    },
    labelOff: {
      type: String,
      default: 'off'
    }
  },
  data() {
    return {
      value: false
    }
  },
  created() {
    this.value = this.initState;
  },
  methods: {
    update(val) {
      this.value = val;
    }
  },
  watch: {
    initState(val) {
      this.value = val;
    },
    value(val) {
      this.$emit('update', val)
    }
  }
}
</script>

<style lang="scss">
.zq--switch{
  margin-right: .5rem;
}
</style>