import * as d3 from 'd3';
import { getRadius } from './radius';

export const createTextElements = (
  wheelGroup,
  pieData,
  tilesData,
  isPreviewSpin,
  sectionsCount,
  getHeightFromHeader,
  getFontFamilyFromClass,
  getSvgTextAnchor,
  handleSectionClick,
  isWheelBgImage
) => {
  const angleBetweenSections = 360 / sectionsCount;
  const radius = getRadius();

  return wheelGroup
    .selectAll('.section-number')
    .data(pieData)
    .enter()
    .append('foreignObject')
    .attr('text-anchor', 'middle')
    .attr('class', 'section-number')
    .attr('id', (d, i) => `section-${i + 1}`)
    .attr('width', (d, i) => tilesData[i] && tilesData[i].text ? radius : radius * Math.sin((angleBetweenSections / 2) * (Math.PI / 180)) * 2)
    .attr('height', (d,i) => {
      const tileText = tilesData[i].text;

      return getHeightFromHeader(tileText);
    })
    .style('white-space', 'nowrap')
    .style('overflow', 'visible')
    .attr('transform', (d, i) => {
      const isVerticallyText = tilesData[i].contraints.includes('isVerticallyText');
      
      if (tilesData[i].text && isVerticallyText) {
        d.innerRadius = 0;
        d.outerRadius = radius;
        d.angle = (d.startAngle + d.endAngle) / 2;
        const rotate = (d.angle * 180 / Math.PI - 90)
        return "rotate(" + rotate + ")translate(" + 0 + "," + (-14) +")";
      } else {
        const angle = i * angleBetweenSections;
        const radians = (angle - 90) * (Math.PI / 180);
        const correction = (360 / sectionsCount / 2);

        const x = (radius) * Math.cos(radians);
        const y = (radius) * Math.sin(radians);

        return `translate(${x},${y}) rotate(${angle + correction}, 0, 0)`;
      }
    })
    .append('xhtml:div')
    .style('width', '100%')
    .style('height', '100%')
    .style('padding-left', (d, i) => {
      const isVerticallyText = tilesData[i].contraints.includes('isVerticallyText');

      if (isPreviewSpin) {
        return tilesData[i].text && isVerticallyText ? '50px' : '15px';
      } else {
        return tilesData[i].text && isVerticallyText ? '25px' : '15px';
      }
    })
    .style('padding-right', (d, i) => {
      if (isPreviewSpin) {
        return tilesData[i].text ? '20px' : '15px';
      } else {
        return tilesData[i].text ? '5px' : '15px';
      }
    })
    .style('margin', '0')
    .style('color', 'white')
    .style('text-align', (d, i) => {
      const tileText = tilesData[i].text;
      const alignClass = tileText.match(/ql-align-(\w+)/);

      if (!tileText) {
        return 'center'
      } else {
        return alignClass ? alignClass[1] : 'left';
      }
    })
    .style('font-family', (d, i) => {
      const tileText = tilesData[i].text;
      const fontMatch = tileText.match(/class="ql-font-(\w+)"/);

      if (fontMatch) getFontFamilyFromClass(fontMatch)
    })
    .html((d, i) => {
      const tileText = tilesData[i].text;
      let content = tileText.length ? tileText : isWheelBgImage ? '' : i + 1;

      if (typeof content === 'string' && content.includes('<p')) {
        content = content.replace(/<p/g, '<p style="margin: 0;"');
      }

      if (typeof content === 'string' && content.includes('<h')) {
        content = content.replace(/<h1/g, '<h1 style="margin: 0;"');
        content = content.replace(/<h2/g, '<h2 style="margin: 0;"');
        content = content.replace(/<h3/g, '<h3 style="margin: 0;"');
        content = content.replace(/<h4/g, '<h4 style="margin: 0;"');
        content = content.replace(/<h5/g, '<h5 style="margin: 0;"');
        content = content.replace(/<h6/g, '<h6 style="margin: 0;"');
      }

      return content;
    })
    .attr('text-anchor', (d, i) => {
      const tileText = tilesData[i].text;
      const alignClass = tileText.match(/ql-align-(\w+)/);

      return  alignClass ? getSvgTextAnchor(alignClass[1]) : 'start';
    })
    .style('font-size', () => isPreviewSpin ? '20px' : '14px')
    .on('click', (d, i) => {
      if (!isPreviewSpin) {
        handleSectionClick(i.id);
      }
    });
};

export const updateTextElements = (
  pieData,
  tilesData,
  isPreviewSpin,
  sectionsCount,
  getHeightFromHeader,
  getFontFamilyFromClass,
  getSvgTextAnchor,
  handleSectionClick,
  isWheelBgImage
) => {
  d3.selectAll('.section-number').remove();
  const wheelGroup = d3.selectAll('.wheel-group');

  createTextElements(
    wheelGroup,
    pieData,
    tilesData,
    isPreviewSpin,
    sectionsCount,
    getHeightFromHeader,
    getFontFamilyFromClass,
    getSvgTextAnchor,
    handleSectionClick,
    isWheelBgImage
  );
}