<template>
  <CDropdown
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
      :caret="false"
  >
    <template #toggler-content>
      <div class="header-icon-buttons">
        <svg xmlns="http://www.w3.org/2000/svg" :width="iconSize" :height="iconSize" viewBox="0 0 24 24">
          <g>
            <g>
              <g>
                <g>
                  <path
                      d="M13.454 2.625c.385 0 .72.261.815.634l.54 2.132c.582.224 1.128.515 1.627.864l2.147-.567c.37-.098.76.067.947.401l1.402 2.499c.19.339.123.764-.163 1.028l-1.362 1.257c.061.367.093.744.093 1.127 0 .376-.03.746-.09 1.106l1.38 1.319c.282.269.342.695.146 1.031L19.49 17.93c-.193.33-.585.489-.954.385l-2.075-.587c-.506.356-1.06.653-1.653.88l-.54 2.133c-.094.373-.43.634-.814.634h-2.908c-.385 0-.72-.261-.815-.634l-.54-2.132c-.591-.228-1.146-.525-1.652-.88l-2.075.586c-.369.104-.76-.054-.954-.385l-1.446-2.474c-.196-.336-.136-.762.145-1.031l1.38-1.32c-.058-.36-.089-.729-.089-1.105s.03-.746.09-1.106L3.21 9.575c-.282-.269-.342-.695-.146-1.031L4.51 6.07c.193-.33.585-.489.954-.385l2.075.587c.506-.356 1.06-.653 1.653-.88l.54-2.133c.094-.373.43-.634.814-.634h2.908zM12 7.5c-2.485 0-4.5 2.015-4.5 4.5s2.015 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.015-4.5-4.5-4.5zm0 3c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5z"
                      transform="translate(-28 -668) translate(20 660) translate(8 8)"/>
                </g>
              </g>
            </g>
          </g>
        </svg>

        <!--        <CIcon name="cil-settings"></CIcon>-->
      </div>

    </template>
    <div class="zq--cog--container">
      <template v-for="item in originalFields">
        <div class="m-1 px-2 zq--cog-items" :key="item">
          <ClCheckbox
              :valueProp="item"
              :label="caseTransform(item)"
              :checkedProp="fields.includes(item)"
              @handler="toggleColumn"
          />
          <CDropdownDivider></CDropdownDivider>
        </div>
      </template>
    </div>

    <CButton class="btn-clear zq--responsive-button" :color="clearButtonType" @click="clearFilter"
    >Clear
    </CButton
    >
  </CDropdown>


</template>

<script>
import {mapGetters} from 'vuex';
import {startCase} from "lodash";
import ClCheckbox from '@/shared/components/formComponents/ClCheckbox';

export default {
  components: {
    ClCheckbox
  },
  props: {
    originalFields: Array,
    fields: Array,
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    iconSize() {
      switch (this.theme) {
        case 'main': {
          return 24
        }
        case 'default': {
          return 16
        }
        default: {
          return 16
        }
      }
    },
    clearButtonType() {
      switch (this.theme) {
        case 'main': {
          return ''
        }
        case 'default': {
          return 'success'
        }
        default: {
          return 'success'
        }
      }
    }
  },
  methods: {
    caseTransform(val) {
      return startCase(val)
    },
    toggleColumn({event}) {
      this.$emit('updateFields', event.target.value);
    },
    clearFilter() {
      this.$emit('clearFilter');
    },
  }
}
</script>

<style lang="scss">
.btn-clear {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.header-icon-buttons {
  svg {
    fill: black;
    color: black;
  }
}

.dropdown-menu.show {
  .zq--cog-items {
    padding-top: .7rem;
  }
}

</style>