<template>
  <CRow class="switch-row">
    <CCol
        :col="colLabel"
        :sm="smLabel"
        :md="mdLabel"
        :lg="lgLabel"
        :xl="xlLabel">
      <label class="col-form-label font-italic">{{ label }}</label>
    </CCol>
    <CCol
        :col="colContent"
        :sm="smContent"
        :md="mdContent"
        :xl="xlContent">
      <CCol
          v-touch:swipe="swipeHandler"
          class="switch-row-wrap">
        <ClSwitch
            class="mr-0"
            :initState="value"
            :isFieldDisabled="disabled"
            @update="updateHandler"
        />
        <IconWithTooltip :text="toolTip" v-if="isShowTooltip"/>
      </CCol>
    </CCol>
  </CRow>
</template>

<script>
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import ClSwitch from '@/shared/components/formComponents/ClSwitch';

export default {
  components: {
    IconWithTooltip,
    ClSwitch
  },
  props: {
    label: String,
    valueProp: Boolean,
    toolTipText: String,
    disabled: {
      type: Boolean,
      default: false
    },
    isShowTooltip: {
      type: Boolean,
      default: true
    },
    colLabel: {
      type: String,
      default: '5'
    },
    smLabel: {
      type: String,
      default: '4'
    },
    mdLabel: {
      type: String,
      default: '3'
    },
    lgLabel: {
      type: String,
      default: '3'
    },
    xlLabel: {
      type: String,
      default: '2'
    },
    // CONTENT
    colContent: {
      type: String,
      default: '6'
    },
    smContent: {
      type: String,
      default: '6'
    },
    mdContent: {
      type: String,
      default: '4'
    },
    xlContent: {
      type: String,
      default: '3'
    },
  },
  data() {
    return {
      value: '',
      toolTip: '',
    }
  },
  created() {
    this.value = this.valueProp;
    this.toolTip = this.toolTipText
  },
  methods: {
    swipeHandler(val) {
      this.$emit('swipeHandler', val);
    },
    updateHandler(val) {
      this.$emit('updateHandler', val)
    }
  },
  watch: {
    valueProp(val) {
      this.value = val;
    }
  }
}
</script>

<style lang="scss">
.switch-row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 14px;


  .switch-row-wrap {
    display: flex;
    align-items: center;
    width: 70px;
    padding-left: 0;

    .fa-question-circle-o {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>