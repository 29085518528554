<template>
  <div>
    <input
      type="checkbox"
      :id="'toggle-' + name"
      class="toggleCheckbox"
      :checked="isChecked"
      :disabled="isDisabled"
      @click="toggle"
    />
    <label
      :for="'toggle-' + name"
      class='toggleContainer'
      :style="{ width: computedWidth, height: computedHeight, opacity: isDisabled ? 0.5 : 1, cursor: isDisabled ? 'not-allowed' : 'pointer' }"
    >
      <span class="textLabel">{{ textLabelOn }}</span>
      <span class="textLabel">{{ textLabelOff }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'SwitchButton',
  props: {
    isChecked: Boolean,
    textLabelOn: String,
    textLabelOff: String,
    name: String,
    width: String,
    height: String,
    isDisabled: Boolean
  },
  data() {
    return {
      defaultWidth: '260px',
      defaultHeight: '28px'
    };
  },
  computed: {
    computedWidth() {
      return this.width ? this.width : this.defaultWidth;
    },
    computedHeight() {
      return this.height ? this.height : this.defaultHeight;
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle', !this.isChecked);
    },
  },
};
</script>

<style scoped lang="scss">
.toggleContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: fit-content;
  height: 28px;
  border: 1px solid #8bb3ff;
  border-radius: 20px;
  background: white;
  font-weight: 600;
  color: #282f37;
  cursor: pointer;
  margin-right: 10px;
}
.toggleContainer::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  border-radius: 50em;
  border: solid 1px #fff;
  background: #007dfb;
  transition: all 0.3s;
}
.toggleCheckbox:checked + .toggleContainer::before {
  left: 50%;
}
.textLabel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.toggleContainer span {
  padding: 1px 6px;
  text-align: center;
  z-index: 1;
}
.toggleCheckbox {
  display: none;
}
.toggleCheckbox:checked + .toggleContainer span:first-child {
  color: rgba(0, 0, 0, .5);
  transition: color 0.3s;
}
.toggleCheckbox:checked + .toggleContainer span:last-child {
  color: white;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer span:first-child{
  color: white;
  transition: color 0.3s;
  font-size: 14px;
  line-height: 23px;
}
.toggleCheckbox + .toggleContainer span:last-child{
  color: rgba(0, 0, 0, .5);
  transition: color 0.3s;
  font-size: 14px;
  line-height: 23px;
}

</style>