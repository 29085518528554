<template>
  <CRow class="products-summary">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ getTitle() }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <!--    Products   -->
            <ClCardRow
              v-if="!productsData.selectedData.length"
              :label="texts.summary.products.productGroupsLabel"
            >
              <template #content>
                <strong class="group__colored">{{ texts.summary.products.everyoneText }}</strong>
              </template>
            </ClCardRow>
            <!--      Should Match At Least      -->
            <ClCardRow
              v-if="productsData.formData.dependantOn.should.length >= 2"
              :label="texts.summary.products.shouldLabel"
              :content="productsData.formData.shouldMatchAtLeast"
            />
            <!--       TABLE     -->
            <CDataTable
              v-if="productsData.selectedData.length"
              id="rewardsResultTable"
              class="zq--table zq--table--default-pagination"
              :items="productsData.selectedData"
              :fields="fields"
              pagination
              :items-per-page="itemsPerPage"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
            >
              <!--      CREATED         -->
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <!--      ID         -->
              <template #id="{ item }" v-if="isPreview">
                <td>
                  <router-link :to="{name: isTag ? 'PreviewTag' : `PreviewProduct`, params: {id: item.id} }">
                    {{ item.id }}
                  </router-link>
                </td>
              </template>
              <template #tags="{item}">
                <td>{{ item.tags }}</td>
              </template>
              <!--       Dependency           -->
              <template #dependency="{ item }">
                <td>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="action-buttons-list">
                      <button
                        class="action-buttons action-buttons__must-not"
                        :class="{'action-buttons--active__must-not': productsData.formData.dependantOn.mustNot.includes(item.key)}"
                      >
                        {{ texts.summary.products.mustNotText }}
                      </button>
                      <button
                        class="action-buttons action-buttons__should"
                        :class="{'action-buttons--active__should': productsData.formData.dependantOn.should.includes(item.key)}"
                      >
                        {{ texts.summary.products.shouldText }}
                      </button>
                      <button
                        class="action-buttons action-buttons__must"
                        :class="{'action-buttons--active__must': productsData.formData.dependantOn.must.includes(item.key)}"
                      >
                        {{ texts.summary.products.mustText }}
                      </button>
                    </div>
                  </div>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {dateFormate} from "@/utils";
import {pageConfig} from "@/config";
import ClCardArrow from "@/shared/UI/ClCardArrow";
import ClCardRow from "@/shared/UI/ClCardRow";
import {mapGetters} from "vuex";
import {competitionsTexts} from "@/config/pageTexts/competitions.json";

export default {
  name: 'Products',
  components: {
    ClCardArrow,
    ClCardRow,
  },
  props: {
    productsData: Object,
    isPreview: {
      type: Boolean,
      default: false
    },
    creationType: {
      type: String,
      default: 'simple'
    }
  },
  data() {
    return {
      texts: {
        ...competitionsTexts
      },
      page: pageConfig.page,
      itemsPerPage: pageConfig.itemsPerPage,
      fields: [
        "id",
        "created",
        "name",
        "description",
        "tags",
        "productRefId",
        "dependency"
      ],
      mustStyle: {
        backgroundColor: '#00b83a',
        color: 'white',
        borderColor: '#00b83a'
      },
      mustNotStyle: {
        backgroundColor: '#fb382b',
        color: 'white',
        borderColor: '#fb382b'
      },
      shouldStyle: {
        backgroundColor: '#ff8719',
        color: 'white',
        borderColor: '#ff8719'
      },
      basicIsCollapsed: false,
      isTag: false
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    },
  },
  created() {
    if (
      this.productsData.selectedData.length
      && typeof this.productsData.selectedData[0] === 'object'
      && Object.hasOwn(this.productsData.selectedData[0], 'productRefId')
    ) {
      this.fields = this.fields.filter(item => item !== 'dependency');
    } else {
      this.fields = this.fields.filter(item => item !== 'tags' && item !== 'productRefId');
      this.isTag = true;
    }
  },
  methods: {
    dateFormate(val) {
      return dateFormate(val);
    },
    getTitle() {
      let title = this.texts.summary.products.title;
      if (this.isTag) {
        title = this.texts.summary.products.tagsTitle;
      }

      return title;
    }
  }
}
</script>

<style lang="scss">
.products-summary {
  .group {
    background: #2eb85c;
    color: #ffffff;
    padding: 0.3rem;
    border-radius: 4px;
  }
}

#rewardsResultTable {
  box-shadow: none;
}
</style>