<template>
  <CRow class="points-strategy-detail">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ texts.scorePoints.title }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <!--       Operator                  -->
            <ClCardRow :label="texts.scorePoints.operator" :content="data.pointsStrategy.operator" />
            <!--       Points Value              -->
            <ClCardRow :label="texts.scorePoints.pointsValue" :content="data.pointsStrategy.pointsValue" />
            <!--       Points Value Upper        -->
            <ClCardRow v-if="isPointsValueUpper" :label="texts.scorePoints.pointsValueUpper" :content="data.pointsStrategy.pointsValueUpper" />
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ClCardRow from '@/shared/UI/ClCardRow.vue';
import ClCardArrow from '@/shared/UI/ClCardArrow.vue';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';

export default {
  name: 'ViewPointsStrategy',
  components: { ClCardArrow, ClCardRow },
  props: {
    scorePointsData: Object
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      basicIsCollapsed: false,
      data: {}
    }
  },
  created() {
    this.data = this.scorePointsData
  },
  computed: {
    isPointsValueUpper() {
      return !!(this.data.pointsStrategy.operator === 'Between' || this.data.pointsStrategy.operator === 'AverageBetween');
    }
  }

};
</script>

<style scoped lang="scss">

</style>