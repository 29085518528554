import * as d3 from 'd3';
import { getRadius } from './radius';

const outerCircleBordersGradientData = [
  { offset: '16%', color: '#F9DF7B' },
  { offset: '30.36%', color: '#B57E10' },
  { offset: '34.67%', color: '#B78113' },
  { offset: '38.98%', color: '#BE8C1F' },
  { offset: '43.29%', color: '#CB9D32' },
  { offset: '47.6%', color: '#DCB64E' },
  { offset: '51.19%', color: '#F2D570' },
  { offset: '52.63%', color: '#F9DF7B' },
  { offset: '53.35%', color: '#FFF3A6' },
  { offset: '58.38%', color: '#F9DF7B' },
  { offset: '76.34%', color: '#B57E10' },
  { offset: '84.24%', color: '#E5C25B' },
  { offset: '87.83%', color: '#F9DF7B' }
];

const defaultWheelBackground = '#5E084B';

export const createCircleBorder = (borderContainer, isPreviewSpin, wheelSettings) => {
  const radius = getRadius();
  const wheelBackground = wheelSettings && wheelSettings.wheelBackground
    ? wheelSettings.wheelBackground
    : defaultWheelBackground;

  const gradient = d3.select('body')
    .append('svg')
    .attr('width', 0)
    .attr('height', 0);

  gradient.append('linearGradient')
    .attr('id', 'circle-borders-gradient')
    .attr('x1', '0%')
    .attr('x2', '100%')
    .selectAll('stop')
    .data(outerCircleBordersGradientData)
    .enter().append('stop')
    .attr('offset', d => d.offset)
    .attr('stop-color', d => d.color);

  const wheelBordersColor = wheelSettings && wheelSettings.wheelBordersColor
    ? wheelSettings.wheelBordersColor
    : 'url(#circle-borders-gradient)';

  borderContainer
    .append('circle')
    .attr('class', 'outer-circle-border')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', radius + 17)
    .attr('fill', 'none')
    .attr('stroke-width', isPreviewSpin ? 40 : 35)
    .attr('stroke', wheelBordersColor);

  borderContainer
    .append('circle')
    .attr('class', 'outer-circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', radius + 17)
    .attr('fill', 'none')
    .attr('stroke-width', isPreviewSpin ? 34 : 28)
    .attr('stroke', wheelBackground);
};

export const updateCircleBorder = (wheelSettings) => {
  const { wheelBackground } = wheelSettings;

  if (!wheelBackground) return;

  const border = d3.select('.outer-circle');
  border.attr('stroke', wheelBackground);
};

export const updateCircleBorders = (wheelSettings) => {
  const { wheelBordersColor } = wheelSettings;

  if (!wheelBordersColor) return;

  const borders = d3.select('.outer-circle-border');
  borders.attr('stroke', wheelBordersColor);
};

export const removeCircleBorders = () => {
  const border = d3.select('.outer-circle');
  border.remove();
  const borders = d3.select('.outer-circle-border');
  borders.remove();
}
