<template>
  <div>
    <CModal
      v-if="!isShowLoader"
      :show.sync="showModal"
      :title="'Select a reward template'"
      centered
      :fade="true"
    >
      <template #header>
        <h5 class="modal-title">{{ 'Reward templates' }}</h5>
        <button type="button" class="close" @click="closeByIcon">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>
      <div class="rewards-list-wrapper">
        <div class="search-container">
          <input type="text" v-model="search" placeholder="Search..." @input="searchData">
          <img
            src="@/assets/icons/search.svg"
            width="16"
            height="16"
            alt="search"
            class="search-icon"
          />
        </div>
        <div class="table-container">
          <table v-if="rewardsList">
            <thead>
            <tr>
              <th>Reward Name</th>
              <th>Reward Type</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="reward in rewardsList" :key="reward.id">
              <td :title="reward.name" class="ellipsis">{{ reward.name }}</td>
              <td :title="reward.rewardType.key" class="ellipsis">{{ reward.rewardType.key }}</td>
              <td><a @click="selectReward(reward)" class="link">Use</a></td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            no rewards found.
          </div>
        </div>
      </div>
      <template #footer-wrapper></template>
    </CModal>
    <TableLoader v-if="isShowLoader"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TableLoader from '@/components/table/Loader.vue';

export default {
  name: 'RewardTemplatesModal',
  components: { TableLoader },
  props: {
    entityType: String,
    isShowRewardTemplatesModal: Boolean
  },
  data() {
    return {
      showModal: false,
      isShowLoader: false,
      rewardsList: null,
      query: [{
        'queryFields': ['tags'],
        'queryValue': 'template'
      }],
      must: [{
        queryField: 'entityType',
        queryValues: [this.entityType === 'instantWins' ? 'InstantWin' : this.entityType]
      }],
      search: '',
      isSelectedReward: false
    };
  },
  watch: {
    isShowRewardTemplatesModal(val) {
      this.showModal = val;
    },
    showModal(val) {
      if (val === true) {
        this.isShowLoader = true;
        this.initialize();
      } else {
        this.search = '';
      }
    }
  },
  methods: {
    ...mapActions('rewards', ['handleGetRewardsByQuery']),
    async initialize() {
      this.handleGetRewardsByQuery({
        queryRequest: {
          must: this.must,
          sortBy: this.sortBy,
          skip: 0,
          limit: 999,
        }
      }).then(data => {
        this.rewardsList = data;
        this.isShowLoader = false;
      });
    },
    selectReward(reward) {
      this.$emit('selectReward', reward);
      this.$emit('setPage', {page: 'reward creation'});
      this.showModal = false;
    },
    closeByIcon() {
      this.$emit('closeModal');
      this.modal = false;
    },
    async searchData(e) {
      if (e.target.value.length > 0) {
        this.handleGetRewardsByQuery({
          queryRequest: {
            must: this.must,
            multiFields: [
              {
                queryFields: [
                  'name',
                  'description'
                ],
                queryValue: e.target.value
              }
            ],
            sortBy: this.sortBy,
            skip: 0,
            limit: 999,
          }
        }).then(data => {
          this.rewardsList = data;
          this.isShowLoader = false;
        });
      } else {
        await this.initialize();
      }
    },
  }
};
</script>

<style scoped lang="scss">
.rewards-list-wrapper {
  width: 100%;
  max-height: 400px;
  padding: 0 10px 10px 10px;
}

.table-container {
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
  z-index: 1;

  &:first-child {
    border-top-left-radius: 10px;
  }

  &:last-child {
    border-top-right-radius: 10px;
  }
}

.ellipsis {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.link:hover {
  color: darkblue;
}

.search-container {
  width: 100%;
  padding: 0 0 10px;
  margin-bottom: 5px;
  position: relative;

  .search-icon {
    position: absolute;
    top: 10px;
    right: 20px;
  }

  > input {
    width: inherit;
    border: 1px solid #D8DBE0;
    height: 34px;
  }

  > input:focus {
    outline: none;
  }
}

::v-deep .modal-content {
  border-radius: 12px;
}

::v-deep .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

::v-deep .modal-footer {
  display: none;
}
</style>
