import { getRadius } from '@/views/ziqni/instant-wins/components/helpers/radius';

function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(this);
    };
    img.onerror = function () {
      reject(new Error('Failed to load image'));
    };
    img.src = url;
  });
}

export const setWheelBackground = async (wheelGroup, radius, sectionsCount, isPreviewSpin, imageUrl) => {
  const angleOffset = 360 / (2 * sectionsCount);
  // const correction = isPreviewSpin ? 200 : 115;
  const imageSize = radius * 2;
  const image = await loadImage(imageUrl);

  wheelGroup
    .insert('image')
    .attr('class', 'wheel-image')
    .attr('xlink:href', image.src)
    .attr('transform', `rotate(${angleOffset})`)
    .attr('x', -(imageSize / 2))
    .attr('y', -(imageSize / 2))
    .attr('width', imageSize)
    .attr('height', imageSize);
};

export const removeWheelBackground = (wheelGroup) => {
  wheelGroup.selectAll('.wheel-image').remove();
};

export const setWheelBorderImage = async (svg, isPreviewSpin, imageUrl) => {
  const radius = getRadius();
  const correction = isPreviewSpin ? 100 : 60;
  const imageSize = radius * 2 + correction;

  const borderImageGroup = svg.insert('g').attr('class', 'wheel-border-image');

  const image = await loadImage(imageUrl);

  borderImageGroup
    .append('image')
    .attr('transform', `translate(${ radius + 4 },${ radius + 4 })`)
    .attr('xlink:href', image.src)
    .attr('x', -(imageSize / 2))
    .attr('y', -(imageSize / 2))
    .attr('width', imageSize)
    .attr('height', imageSize);

  // Make sure the border image is behind other elements
  svg.node().insertBefore(borderImageGroup.node(), svg.node().firstChild);
};

export const removeWheelBorderImage = (svg) => {
  svg.selectAll('.wheel-border-image').remove();
};
