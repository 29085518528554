<template>
  <div class="ach-reward-preview">
    <!--  Header    -->
    <div>
      <LocalBreadcrumb
        :pageNumber="pageNumber"
        :items="breadcrumbItems"
        @setCurrentPage="toPage"
      />
      <div class="d-flex justify-content-end">
        <ClNavButton
          :label="$t('buttons.goBack')"
          @handler="goBack"
        />
      </div>
    </div>
    <!-- Content   -->
    <CRow class="mt-2 mr-0">
      <CCol class="pr-0">
        <CCard class="zq--wizard-card">
          <CCardHeader>
            <div class="d-flex justify-content-between">
              <strong class="title">{{ rewardsTexts.previewPage.title }}</strong>
              <CLink
                class="card-header-action btn-minimize"
                @click="basicIsCollapsed = !basicIsCollapsed"
              >
                <ClCardArrow :is-collapsed="basicIsCollapsed"/>
              </CLink>
            </div>
          </CCardHeader>

          <CCollapse :show="basicIsCollapsed" :duration="400">
            <CCardBody>
              <!--    Icon        -->
              <ClCardRow :label="rewardsTexts.previewPage.iconLabel" :content="rewardData.icon"/>
              <!--     Reward Name         -->
              <ClCardRow :label="rewardsTexts.previewPage.nameLabel" :content="rewardData.name"/>
              <!--    Value        -->
              <ClCardRow :label="rewardsTexts.previewPage.valueLabel" :content="rewardData.rewardValue"/>
              <!--    Reward Rank        -->
              <ClCardRow :label="rewardsTexts.previewPage.rewardRankLabel" :content="rewardData.rewardRank"/>
              <!--    Reward Type         -->
              <ClCardRow :label="rewardsTexts.previewPage.typeLabel" :content="rewardData.rewardType.key"/>
              <!--    Reward Type         -->
              <ClCardRow :label="rewardsTexts.previewPage.typeIdLabel" :content="rewardData.rewardTypeId"/>
              <!--    Period        -->
              <ClCardRow :label="rewardsTexts.previewPage.periodLabel" :content="rewardData.period"/>
              <!--    Delay        -->
              <ClCardRow :label="rewardsTexts.previewPage.delayLabel" :content="rewardData.delay"/>
              <!--     Point In Time        -->
              <ClCardRow :label="rewardsTexts.previewPage.pointLabel" :content="dateFormate(rewardData.pointInTime)"/>
              <!--     Description        -->
              <ClCardRow :label="rewardsTexts.previewPage.descriptionLabel" :content="rewardData.description"/>
              <!--      constraints        -->
              <ClCardRow :label="rewardsTexts.previewPage.constraintsLabel" v-if="rewardData.constraints">
                <template #content>
                  <ul class="zq--list">
                    <li v-for="(group, index) in rewardData.constraints"
                        :key="index" class="group__colored mr-2">{{ group }}
                    </li>
                  </ul>
                </template>
              </ClCardRow>
              <!--      add constraints        -->
              <ClCardRow :label="rewardsTexts.previewPage.constraintsLabel" v-if="rewardData.addConstraints">
                <template #content>
                  <ul class="zq--list">
                    <li v-for="(group, index) in rewardData.addConstraints"
                        :key="index" class="group__colored mr-2">{{ group }}
                    </li>
                  </ul>
                </template>
              </ClCardRow>
              <!--      Metadata        -->
              <ClCardRow :label="rewardsTexts.previewPage.metadataLabel" v-if="rewardData.metadata">
                <template #content>
                  <ul class="members-metadata--list">
                    <li
                      v-for="(meta, index) in Object.entries(rewardData.metadata)"
                      class="members-metadata--item"
                      :key="index"
                    >
                      <span>{{ meta[0] }} : </span><span>{{ meta[1] }}</span>
                    </li>
                  </ul>
                </template>
              </ClCardRow>
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import LocalBreadcrumb from '@/shared/components/LocalBreadcrumb';
import {dateFormate, getTranslatableFields} from '@/utils';
import ClNavButton from "@/shared/components/formComponents/ClNavButton";
import ClCardRow from "@/shared/UI/ClCardRow";
import ClCardArrow from "@/shared/UI/ClCardArrow";
import {rewardsTexts} from "@/config/pageTexts/rewards.json";

export default {
  components: {
    LocalBreadcrumb,
    ClNavButton,
    ClCardRow,
    ClCardArrow
  },
  props: {
    rewardData: Object,
    pageNumber: {
      type: [String, Number],
      default: "5"
    }
  },
  data() {
    return {
      basicIsCollapsed: true,
      breadcrumbItems: ['Rewards', 'PreviewReward'],
      rewardsTexts: {
        ...rewardsTexts
      },
    }
  },
  methods: {
    toPage() {
      this.$emit('setPage', {page: 'rewards'});
    },
    goBack() {
      this.$emit('setPage', {page: 'rewards'});
    },
    getTranslationFields() {
      return getTranslatableFields(this.rewardData);
    },
    dateFormate(val) {
      return dateFormate(val);
    }
  }
}
</script>

<style lang="scss">
.ach-reward-preview {


  .detail-label {
    @media (min-width: 420px) {
      max-width: 220px;
    }
  }

  .nxt-col-mobile {
    @media (max-width: 420px) {
      text-align: center;
    }
  }

  .overview-label {
    font-style: italic;
    color: darkgray;
  }

  .files-metadata--list {
    margin: 0;
    padding: 0;
    font-weight: bold;
  }
}
</style>