<template>
  <CRow>
    <CCol col="12" class="d-flex">
      <div class="mb-3 mr-2 bread-content">
        <span class="mr-2" v-if="isPageNumber">{{ pageNumber }}.</span>
        <span
            v-for="(item, index) in breadcrumb"
            :key="index">
             <span
                 @click="toPage"
                 class="settings-bread">{{ item.path }}</span>

          <i class="mr-2"> {{ item.divider }}</i>
        </span>
        <span>{{ currentBreadcrumb }}
          <IconWithTooltip v-if="withTooltip" :text="toolTipText" class="font-xl" />
        </span>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import IconWithTooltip from '@/shared/UI/IconWithTooltip';

export default {
  components: {
    IconWithTooltip
  },
  props: {
    items: Array,
    pageNumber: [String, Number],
    toolTipText: String,
    isPageNumber: {
      type: Boolean,
      default: true
    },
    withTooltip: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentBreadcrumb() {
      return this.items[this.items.length - 1];
    },
    breadcrumb() {
      const fields = this.items;
      const breadArray = fields.splice(0, this.items.length - 1);
      return breadArray.reduce((acc, item) => {
        return [
          ...acc,
          {
            divider: '/',
            path: item
          }
        ]
      }, [])
    }
  },
  methods: {
    toPage(e) {
      this.$emit('setCurrentPage', e.target.textContent.toLowerCase());
    },
  }
}
</script>

<style lang="scss" scoped>
.settings-bread-content {
  width: 100%;
  font-size: 22px;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  justify-content: center;
  align-items: center;
}

.settings-bread {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>