<template>
  <div class="scratch-cards-block">
    <div v-for="(row, rowIndex) in grid" :key="rowIndex" class="scratch-cards-row">
      <div
          v-for="(cell, colIndex) in row"
          :key="colIndex"
          class="cell"
          :class="{'selected-cell': !previewMode && cell.cellNumber === selectedCell}"
          @click="selectCell( cell, rowIndex, colIndex)"
          :style="{
            'background-image': iconUris[cell.cellNumber - 1] ? `url(${iconUris[cell.cellNumber - 1]})` : 'none',
            'background-size': 'cover',
            'background-position': 'center',
            'background-repeat': 'no-repeat',
            'background-color': `var(--cell-background-color, ${cellBackground(cell.cellNumber)})`,
          }"
      >
        <span class="cell-text" v-html="cellText(cell.cellNumber)"></span>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'ScratchCard',
  data() {
    return {
      grid: this.createGrid(),
      selectedCell: 1,
      iconUris: [],
    };
  },
  props: {
    tilesCount: {
      type: [Number, String],
      default: 9,
    },
    tilesData: Array,
    cols: {
      type: [Number, String],
      default: 3,
    },
    rows: {
      type: [Number, String],
      default: 3,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    selectedTile: String
  },
  watch: {
    tilesData: {
      deep: true,
      async handler(newVal) {
        this.grid = this.createGrid()
      }
    },
  },
  created() {
    if (this.selectedTile) {
      this.selectedCell = Number(this.selectedTile);
    }
    this.updateSectionBackgrounds();
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjects_item']),
    createGrid() {
      const grid = [];
      let cellNumber = 0
      for (let i = 0; i < this.rows; i++) {
        const row = [];
        for (let j = 0; j < this.cols; j++) {
          cellNumber++;
          row.push({ cellNumber });
        }
        grid.push(row);
      }
      return grid;
    },
    cellText(cellNumber) {
      const cell = this.tilesData[cellNumber - 1];

      if (cell && cell.text) {
        let content = cell.text;

        if (typeof content === 'string' && content.includes('<p')) {
          content = content.replace(/<p/g, '<p style="margin: 0;"');
        }

        if (typeof content === 'string' && content.includes('<h')) {
          content = content.replace(/<h1/g, '<h1 style="margin: 0;"');
          content = content.replace(/<h2/g, '<h2 style="margin: 0;"');
          content = content.replace(/<h3/g, '<h3 style="margin: 0;"');
          content = content.replace(/<h4/g, '<h4 style="margin: 0;"');
          content = content.replace(/<h5/g, '<h5 style="margin: 0;"');
          content = content.replace(/<h6/g, '<h6 style="margin: 0;"');
        }
        return content;
      } else {
        return cellNumber;
      }
    },
    cellBackground(cellNumber) {
      return this.tilesData[cellNumber - 1].background;
    },
    selectCell(cell, row, col) {
      this.selectedCell = cell.cellNumber;
      this.$emit('handleTileSelected', cell.cellNumber);
    },
    updateSelectedCell(value) {
      this.selectedCell = value;
    },
    async updateSectionImages() {
      const iconUris = [];
      for (let i = 0; i < this.tilesData.length; i++) {
        const iconId = this.tilesData[i].icon;

        if (iconId) {
          const file = await this.handleGetFileObjects_item({ idArray: [iconId] });

          if (file && file.length > 0) {
            iconUris.push(file[0].uri);
          } else {
            iconUris.push(null);
          }
        } else {
          iconUris.push(null);
        }
      }
      this.iconUris = iconUris;
    },
    updateSectionBackgrounds() {
      setTimeout(async () => {
        await this.updateSectionImages()
      }, 100)
    },
  }
};
</script>


<style scoped lang="scss">

.scratch-cards-block {
  padding: 30px 100px 0;

  .scratch-cards-row {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin: 0 5px;
    border: 1px solid #406A8C;
    box-shadow: 0 2px 12px rgba(64, 106, 140, 0.5);
    border-radius: 5px;
    overflow: hidden;
    position: relative;

    &.selected-cell {
      transform: scale(1.1);
      border-color: #007DFB;
      border-width: 2px;
    }

    .cell-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      z-index: 10;
      text-align: center;
    }

  }
}
</style>