<template>
<div>
  <CModal
    :show.sync="showModal"
    centered
    fade
  >
    <template #header>
      <h5 class="modal-title">{{ 'Rules templates' }}</h5>
      <button type="button" class="close" @click="closeByIcon">
        <span aria-hidden="true">&times;</span>
      </button>
    </template>

    <TemplatesTable
      @getFromTemplate="getFromTemplate"
      :context="context"
    />

    <template #footer-wrapper></template>
  </CModal>
</div>
</template>

<script>
import TemplatesTable from '@/shared/components/supportModels/rules/TemplatesTable.vue';

export default {
  name: 'RulesTemplateModal',
  components: { TemplatesTable },
  props: {
    isTemplatesTableModal: Boolean,
    context: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  watch: {
    isTemplatesTableModal(val) {
      this.showModal = val;
    },
  },
  methods: {
    closeByIcon() {
      this.$emit('closeModal');
      this.showModal = false;
    },
    getFromTemplate(item) {
      this.$emit('getFromTemplate', item);
    },
  }
};
</script>


<style scoped lang="scss">
::v-deep .modal-footer {
  display: none;
}

::v-deep .modal-dialog {
  max-width: 1200px;
  width: 100%;
}
</style>
