<template>
  <div class="zq--steps-actions">
    <div class="zq--steps-actions--content">
      <ClNavButton
        :label="$t('buttons.previous')"
        :disabled="currentStep === 0"
        @handler="prevStep" class="mr-2"
      />
      <ClNavButton
        :label="$t('buttons.next')"
        btnType="next"
        :disabled="currentStep >= totalStep"
        @handler="nextStep"
        class="mr-2 zq--steps-actions--btn__lg"
      />
      <CButton
        :color="theme === 'default' ? 'success' : ''"
        @click="finish"
        :disabled="currentStep < totalStep"
        class="wizard-action-btn zq--responsive-button__common"
      >
        Finish
      </CButton>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ClNavButton from '@/shared/components/formComponents/ClNavButton';

export default {
  components: {
    ClNavButton
  },
  props: {
    currentStep: Number,
    totalStep: Number,
    validation: Boolean,
    finishAction: Function,
  },
  computed: {
    ...mapGetters('theme', ['theme'])
  },
  methods: {
    prevStep() {
      this.$emit('updateCurrentStep', this.currentStep - 1);
    },
    nextStep() {
      this.$emit('next-step')
    },
    finish() {
      this.finishAction()
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";
.zq--steps-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding:0  10px;
  height: 100%;
  .zq--steps-actions--content {
    display: flex;
    justify-content: flex-end;
    @include media-breakpoint-down('576px') {
      width: 100%;
      justify-content: space-between;
      :nth-child(1) {
        order: 1;
      }
      :nth-child(2) {
        order: 3;
      }
      :nth-child(3) {
        order: 2;
      }
    }
  }
}


.wizard-action-btn {
  position: relative;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;

  &:focus {
    box-shadow: none;
  }
}
</style>
