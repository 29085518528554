<template>
  <div>
    <CRow class="mt-3">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody>
            <CDataTable
              id="dataTableId"
              ref="dataTableRef"
              class="zq--table"
              :items="instantWinTileData"
              :fields="tableFields"
              :responsive="true"
              :border="false"
            >
              <template #col="{item}">
                <td>{{ item.location.col }}</td>
              </template>
              <template #row="{item}">
                <td>{{ item.location.row }}</td>
              </template>
              <template #contraints="{item}">
                <td>{{ item.contraints }}</td>
              </template>
              <template #probability="{item}">
                <td>{{ item.probability ? item.probability : 'Auto' }}</td>
              </template>
              <template #reward="{item}">
                <td>
                  <div class="reward" v-if="item.reward && Object.keys(item.reward).length !== 0">
                    <span v-if="$route.name === 'PreviewInstantWin' && item.reward['name']">
                        <a href="#" @click.prevent="showReward(item.reward)">
                          {{ item.reward.name }}
                        </a>
                      </span>
                    <div class="reward-data" v-if="$route.name !== 'PreviewInstantWin'">
                      <span v-if="item.reward['name']">Name: {{ item.reward.name }}</span>
                      <span v-if="item.reward['rewardValue']">Value: {{ item.reward.rewardValue }}</span>
                      <span v-if="item.reward['rewardType']">Type: {{ item.reward.rewardType.key }}</span>
                    </div>
                  </div>
                </td>
              </template>
              <template #text="{item}">
                <td>{{ truncateAndClearText(item.text) }}</td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <Modal
      :modalShow="isModal"
      :messageGeneral="modalText"
      :title="modalTitle"
      :isSuccessButton="false"
      v-on:toggle-modal="isModal = false"
      @closeModal="isModal = false"
    />
  </div>
</template>

<script>
import Modal from '@/shared/UI/Modal.vue';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';

export default {
  name: 'instantWinTileView',
  components: { Modal },
  props: {
    instantWinTileData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      tableFields: ['col', 'row', 'contraints', 'probability', 'reward', 'text'],
      isModal: false,
      modalTitle: achievementsTexts.previewPage.rewards.modalTitle,
      modalText: '',
    };
  },
  methods: {
    truncateAndClearText(text) {
      text = text.replace(/<[^>]*>?/gm, ' ').replace(/\s+/g, ' ').trim();
      let sliced = text.slice(0, 100);
      if (sliced.length < text.length) {
        sliced += '...';
      }

      return sliced;
    },
    showReward(reward) {
      this.modalText = `
        <p><b>Id:</b> <a href="#/rewards/detail/${ reward.id }">${ reward.id }</a></p>
        <p><b>Name:</b> ${ reward.name }</p>
        <p><b>Reward Type:</b> ${ reward.rewardType && reward.rewardType.key ? reward.rewardType.key : '' }</p>
        <p><b>Reward Type Id:</b> <a href="#/settings/reward-types/detail/${ reward.rewardTypeId }">${ reward.rewardTypeId }</a></p>
        <p><b>Value:</b> ${ reward.rewardValue }</p>
      `;
      this.isModal = true;
    },
  }
};
</script>


<style scoped lang="scss">
.reward {
  display: flex;
  justify-content: center;

  .reward-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
