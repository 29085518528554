<template>
  <div class="table-rewards">
    <div class="rewards-header">
      <LocalBreadcrumb
        v-if="!isUpdate && !isNoTitle"
        :pageNumber="pageNumber"
        :isPageNumber="isPageNumber"
        :toolTipText="descriptions.rewards"
        :items="['Rewards']"
      />

      <div class="rewards-header--actions" :class="{ rewardsUpdateHeader: isUpdate }">
        <!--   Fields     -->
        <CogFields
          :originalFields="originalFields"
          :fields="fields"
          @updateFields="updateFields"
          @clearFilter="clearFilter"
        />
        <CDropdown
          inNav
          class="c-header-nav-items action-button-groups mr-2 custom-dropbox"
          placement="bottom-end"
          add-menu-classes="p-0"
          v-if="selectedRewards.length >= 1"
        >
          <template #toggler>
            <CButton
              variant="outline"
              :color="colorBtn"
              class="zq--custom-button"
            >
              {{ $t('buttons.action') }}
              <i v-if="theme === 'main'" class="fa fa-angle-down font-weight-bold"></i>
              <i v-else class="fa fa-caret-down"></i>
            </CButton>
          </template>
          <CDropdownItem class="p-0 actions-dropdown-item">
            <CButton
              pill
              :color="colorBtn"
              class="action-custom-button"
              variant="ghost"
              :disabled="disabled"
              @click="deleteRewards"
            >
              {{ $t('buttons.delete') }}
            </CButton>
          </CDropdownItem>
        </CDropdown>
        <CButton
          class="mr-2 zq--custom-button"
          :color="colorBtn"
          variant="outline"
          :disabled="disabled"
          @click="addFromTemplate"
        >
          <i class="fa fa-plus zq--button-icon mr-2"></i>
          <span>{{ $t('buttons.AddFromTemplate') }}</span>
        </CButton>
        <!--    create    -->
        <ClResponsiveButton :disabled="disabled" :label="$t('buttons.create')" @handler="createNewReward" class="create-btn"/>
        <!--    add    -->
        <!--        <ClResponsiveButton :disabled="disabled" :label="$t('buttons.add')" @handler="addReward" />-->
      </div>
    </div>
    <!--    <div class="selected-rewards" v-show="chosenRewards.length">-->
    <!--      &lt;!&ndash;  Selected Rewards  &ndash;&gt;-->
    <!--      <Rewards :rewardsData="chosenRewards" title="Selected Rewards"/>-->
    <!--    </div>-->
    <!--  TAble  -->
    <CRow class="table-rewards-content">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody>
            <CDataTable
              id="rewardsResultTable"
              class="zq--table"
              :items="rewards"
              :fields="tableFields"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="loading"
            >
              <template #loading>
                <TableLoader/>
              </template>
              <template #id="{item}">
                <td @click="showPreview(item)">
                  <CLink>
                    {{ item.id }}
                  </CLink>
                </td>
              </template>
              <template #name="{item}">
                <td @click="showPreview(item)">
                  <CLink>
                    {{ item.name }}
                  </CLink>
                </td>
              </template>
              <template #rewardType="{item}">
                <td>{{ item.rewardType.key }}</td>
              </template>
              <template #customFields="{item}">
                <td>{{ item.customFields }}</td>
              </template>
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <template #pointInTime="{item}">
                <td>{{ dateFormate(item.pointInTime) }}</td>
              </template>
              <template #metadata="{item}">
                <td>{{ getMetaData(item.metadata) }}</td>
              </template>
              <template #period="{item}">
                <td>{{ item.period }}</td>
              </template>
              <template #description="{item}">
                <td v-html="item.description "></td>
              </template>
              <template #icon="{item}">
                <td>{{ item.icon }}</td>
              </template>
              <template #spaceName="{item}">
                <td>{{ item.spaceName }}</td>
              </template>
              <template #entityType="{item}">
                <td>{{ item.entityType }}</td>
              </template>
              <template #entityId="{item}">
                <td>{{ item.entityId }}</td>
              </template>
              <template #Expires="{item}">
                <td>{{ dateFormate(item.pointInTime) }}</td>
              </template>

              <template #translatableFields="item">
                <td>
                  {{ getTranslatableFields(item) }}
                </td>
              </template>

              <!--            Select All-->
              <template #select-header class="text-center">
                <div class="position-relative zq--table-select-all" v-theme-select-all>
                  <ClCheckbox
                    :checkedProp="toggleFlag"
                    icon="fa-angle-down"
                    @handler="selectAll"
                  />
                </div>
              </template>
              <!--            Select-->
              <template #select="{ item }">
                <td :data-id="item.id" class="text-center select-td fixed-column-start">
                  <ClCheckbox
                    :valueProp="item.id"
                    :checkedProp="selectedRewards.indexOf(item.id) > -1"
                    @handler="checkRewards"
                  />
                </td>
              </template>

              <!--    Actions block          -->
              <template #actions="{ item }">
                <td
                  :data-id="item.id"
                  :class="{'zq--active-fixed-column': activeToggleId === item.id}"
                  class="fixed-column-end"
                >
                  <div class="zq--table-actions-wrap">
                    <ul class="m-0 p-0">
                      <CDropdown
                        inNav
                        class="c-header-nav-items custom-dropbox"
                        placement="left"
                        add-menu-classes="p-0"
                      >
                        <template #toggler>
                          <CButton class="zq--table-actions--single" @click="handleToggle(item.id)">
                            <ClIcon cilIcon="cil-options" iconTypeProp="cil" imgIcon="actions-more" />
                          </CButton>
                        </template>
                        <CDropdownItem class="p-0">
                          <CButton
                            pill
                            color="dark"
                            class="action-custom-button"
                            variant="ghost"
                            @click="showPreview(item)"
                          >
                            {{ $t('buttons.view') }}
                          </CButton>
                        </CDropdownItem>
                        <CDropdownItem class="p-0">
                          <CButton
                            pill
                            color="dark"
                            class="action-custom-button"
                            variant="ghost"
                            @click="showEdit(item)"
                          >
                            {{ $t('buttons.edit') }}
                          </CButton>
                        </CDropdownItem>
                        <CDropdownItem class="p-0">
                          <CButton
                            pill
                            color="dark"
                            :disabled="disabled"
                            class="action-custom-button"
                            variant="ghost"
                            @click="
                              deleteModal = true;
                              selectedId = [item.id];
                            "
                          >
                            {{ $t('buttons.delete') }}
                          </CButton>
                        </CDropdownItem>
                      </CDropdown>
                    </ul>
                  </div>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- Delete Reward Modal  -->
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="messageGeneral"
      :title="delRewardsTitle"
      @doFunction="deleteReward"
      v-on:toggle-modal="closeModal"
    />
  </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {cloneDeep, uniq, uniqBy} from "lodash";
import {dateFormate, getMetaData, getTranslatableFields} from '@/utils';
import CogFields from '@/components/header/CogFields';
import Modal from '@/shared/UI/Modal';
import ClResponsiveButton from "@/shared/components/formComponents/ClResponsiveButton";
import ClCheckbox from "@/shared/components/formComponents/ClCheckbox";
import ClIcon from "@/shared/UI/ClIcon";
import LocalBreadcrumb from "@/shared/components/LocalBreadcrumb";
import {achievements} from "@/config/descriptions/achievements";
import TableLoader from "@/components/table/Loader";
import {achievementsTexts} from "@/config/pageTexts/achievements.json";
// import Rewards from '../../Summary/components/Rewards';

export default {
  components: {
    Modal,
    TableLoader,
    CogFields,
    LocalBreadcrumb,
    ClResponsiveButton,
    ClCheckbox,
    ClIcon,
    /*Rewards*/
  },
  props: {
    rewardData: Array,
    chosenRewards: Array,
    pageNumber: [String, Number],
    disabled: {
      type: Boolean,
      default: false
    },
    isPageNumber: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
    isNoTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deleteModal: false,
      toggleFlag: false,
      selectedId: null,
      messageGeneral: achievementsTexts.createPage.rewards.deleteMessage,
      delRewardsTitle: achievementsTexts.createPage.rewards.deleteTitle,
      rewards: [],
      selectedRewards: [],
      activeToggleId: null,
      descriptions: {
        rewards: achievements.list.create.rewards.rewards,
      },
      texts: {
        ...achievementsTexts
      },
      tableFields: ['name', 'rewardValue', 'rewardRank', 'rewardType', 'Expires', 'actions'],
    }
  },
  computed: {
    ...mapGetters('rewards', ['fields', 'originalFields']),
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    },
    colorBtn() {
      switch (this.theme) {
        case 'main': {
          return ''
        }
        case 'default': {
          return 'dark'
        }
        default: {
          return ''
        }
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('rewards', ['handleFields', 'handleClearFilter']),
    initialize() {
      this.rewards = this.rewardData.length ? this.rewardData : [];
    },
    // FIX Z-INDEX ISSUE
    handleToggle(id) {
      this.activeToggleId = id;
    },
    getTranslatableFields({item}) {
      return getTranslatableFields(item);
    },
    createNewReward() {
      this.$emit('createNewReward')
    },
    /*addReward() {
      const rewards = this.rewards.filter(reward => {
        return this.selectedRewards.includes(reward.id)
      })
      this.$emit('addReward', {rewards})
    },*/
    dateFormate(val) {
      const date = dateFormate(val);
      return date === 'Invalid Date' ? '' : date
    },
    getMetaData(items) {
      return getMetaData(items)
    },
    checkRewards({event}) {
      const index = this.selectedRewards.indexOf(event.target.value);
      if (index > -1) {
        this.selectedRewards.splice(index, 1);
      } else {
        this.selectedRewards.push(event.target.value);
      }
    },
    selectAll() {
      const rewards = cloneDeep(this.rewards);
      this.toggleFlag = !this.toggleFlag;
      if (this.toggleFlag) {
        rewards.forEach(item => this.selectedRewards.push(item.id));
      } else {
        this.selectedRewards = [];
      }
    },
    showPreview(item) {
      this.$emit('setPage', {page: 'preview reward', data: item});
    },
    showEdit(item) {
      this.$emit('updateRewardsData', [item]);
      this.$emit('setPage', {page: 'reward creation', data: item});
    },
    deleteReward() {
      this.deleteModal = false;
      this.rewards = this.rewards.filter((item) => !this.selectedId.includes(item.id));
      this.selectedRewards = [];
      this.toggleFlag = false;
      this.$emit('updateRewards', this.rewards);
    },
    deleteRewards() {
      const selected = uniq(this.selectedRewards).length;
      const item = selected <= 1 ? 'reward' : 'rewards';
      this.messageGeneral = `You are about to delete ${selected} ${item}. Are you sure?`
      this.deleteModal = true;
      this.selectedId = cloneDeep(this.selectedRewards);
    },
    closeModal() {
      this.deleteModal = false;
      this.selectedId = null;
    },
    addFromTemplate() {
      this.$emit('addFromTemplate')
    },
    updateFields(val) {
      this.handleFields(val);
    },
    clearFilter() {
      this.handleClearFilter();
    },

  },
  watch: {
    selectedRewards: {
      deep: true,
      handler: function (val) {
        if (!val.length) this.toggleFlag = false;
      },
    },
    rewardData: {
      deep: true,
      handler: function (val) {
        this.rewards = val;
      },
    },
  }
}
</script>

<style lang="scss">
@import "~@coreui/coreui/scss/coreui";

.table-rewards {
  height: 100%;
  margin-top: 0;
  padding: 0;
  .zq--table table .fixed-column-end {
    position: relative !important;
    z-index: unset !important;
  }
  .rewardsUpdateHeader {
    padding-top: 1rem;
  }
  .rewards-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--actions {
      // d-flex justify-content-end align-items-center flex-wrap
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      min-height: 42px;
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
    .c-header-nav-items.dropdown {
      .btn {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .table-rewards-subtitle {
    padding-top: 1rem;
    background: var(--zq-sub-bg);
  }
  .table-rewards-content {
    padding-top: 1rem;
    background: var(--zq-sub-bg);
    height: 100%;
  }
  .rewards-header--actions {
    .create-btn {
      margin-right: 8px;
    }
  }
  .selected-rewards {
    margin-top: 16px;
  }
}
</style>
