<template>
  <div class="zq--metadata">
    <template v-for="(field, index) in metaVals">
      <CRow form>
        <CCol sm="4" md="4" lg="5" xl="3">
          <ClRowInput
            type="text"
            name="metakey"
            v-model="field.key"
            :placeholder="placeholders.key"
            :disabled="disabled"
            :invalidFeedback="invalidMessage"
            :is-valid="isMetaKeyValid"
          />
          <!--          <CInput-->
          <!--              type="text"-->
          <!--              name="metakey"-->
          <!--              v-model="field.key"-->
          <!--              :placeholder="placeholders.key"-->
          <!--              :disabled="disabled"-->
          <!--              :invalidFeedback="invalidMessage"-->
          <!--              :is-valid="isMetaKeyValid"-->
          <!--          />-->
        </CCol>
        <CCol sm="4" md="4" lg="5" xl="3">
          <ClRowInput
            type="text"
            name="metavalue"
            v-model="field.value"
            :placeholder="placeholders.value"
            :disabled="disabled"
          />
          <!--          <CInput-->
          <!--              type="text"-->
          <!--              name="metavalue"-->
          <!--              v-model="field.value"-->
          <!--              :placeholder="placeholders.value"-->
          <!--              :disabled="disabled"-->
          <!--          />-->
        </CCol>

        <CCol sm="4" md="3" lg="2" class="zq--metadata--actions" v-show="!disabled">
          <CButton
            type="button"
            :color="setBtnColor('danger')"
            size="sm"
            class="zq--small-button"
            @click="deleteField(index)"
          >
            <CIcon name="cil-minus" />
          </CButton>
          <CButton
            type="button"
            :color="setBtnColor('success')"
            class="zq--small-button ml-2"
            size="sm"
            :disabled="!field.value || !field.key"
            @click="addField(index)"
            v-if="metaVals.length - 1 === index"
          >
            <CIcon name="cil-plus" />
          </CButton>
        </CCol>
      </CRow>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClRowInput from '@/components/forms/ClRowInput';

export default {
  components: {
    ClRowInput,
  },
  data() {
    return {
      metaVals: [{ key: '', value: '' }],
      placeholders: { key: 'Metadata key', value: 'Metadata value' },
      isMetaKeyValid: null,
    };
  },
  props: {
    fields: Object,
    disabled: Boolean,
    addMeta: Function,
    headers: Boolean,
    isValid: Boolean,
    invalidMessage: String,
  },
  computed: {
    ...mapGetters('theme', ['theme']),
  },
  methods: {
    /**
     * Validation empty key/value in metaVals
     * */
    validateMetaVals(val) {
      if (val.length > 0) {
        const d = {};
        val.forEach((v) => {
          if (v.key != '') {
            d[v.key] = v.value;
          }
        });
        return d;
      }
      return {};
    },
    addMetaData() {
      this.$emit('change', this.validateMetaVals(this.metaVals));
    },
    addField: function (index) {
      this.metaVals.splice(index + 1, 0, { key: '', value: '' });
    },
    deleteField: function (index) {
      if (this.metaVals.length === 1) {
        this.metaVals = [{ key: '', value: '' }];
      }
      if (this.metaVals.length > 1) {
        this.metaVals.splice(index, 1);
      }
    },
    checkHeaders() {
      if (this.headers) {
        this.placeholders.key = 'Header Key';
        this.placeholders.value = 'Header Value';
      }
    },
    setBtnColor(color) {
      switch (this.theme) {
        case 'main': {
          return '';
        }
        case 'default': {
          return color;
        }
        default: {
          return '';
        }
      }
    },
    init() {
      this.checkHeaders();
      if (Object.keys(this.fields).length > 0) {
        const keys = Object.keys(this.fields);
        const metaData = [];
        for (const key of keys) {
          metaData.push({ key: key, value: this.fields[key] });
        }
        this.metaVals = Object.assign([], metaData);
      } else {
        this.metaVals = [{ key: '', value: '' }];
      }
    }
  },
  updated() {
    this.addMetaData();
    this.checkHeaders();
  },
  created() {
    this.init();
  },
  watch: {
    isValid(val) {
      this.isMetaKeyValid = val;
    },
    // fields: {
    //   deep: true,
    //   handler() {
    //     this.init();
    //   }
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "~@coreui/coreui/scss/coreui";

.zq--metadata {
  .zq--metadata--actions {
    min-width: fit-content;
    @include media-breakpoint-down(lg) {
      text-align: right;
    }
  }
}
</style>
